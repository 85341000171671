import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../../api/blockchain/events";
import { useRejectLedgerMutation } from "../../../../api/rest/company";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import {
  LedgerApprovalInitializedEvent,
  LedgerRollbackPending,
} from "../../../../types/models/events";
import type { LedgerVersion } from "../../../../types/models/shares";
import { Button } from "../../../design-system/Button";
import { Dialog } from "../../../design-system/Dialog";
import { FormErrorList } from "../../../design-system/FormGroup";

type ApproveConfirmationProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  version: LedgerVersion;
  isRollback: boolean;
};

const RejectConfirmation = ({
  currentCompany,
  version,
  isRollback,
  onClose,
  onSuccess,
}: ApproveConfirmationProps) => {
  const i18n = useTranslation();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = eventsQuery.data?.data || [];
  const rollbackPending = events.find(
    (e) => e.type === "LedgerRollbackPending"
  ) as LedgerRollbackPending;
  const approvalInitialisedEvent = events.find(
    (e) => e.type === "LedgerApprovalInitialized"
  ) as LedgerApprovalInitializedEvent;

  const rejectLedgerApprovalMutation = useRejectLedgerMutation(
    currentCompany.orgNumber,
    version,
    rollbackPending,
    approvalInitialisedEvent,
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );

  return (
    <Dialog
      isOpen
      title={i18n.t(
        isRollback
          ? "eventsWizard.rejectConfirmation.rollback.title"
          : "eventsWizard.rejectConfirmation.review.title"
      )}
      onClose={onClose}
      isLoading={rejectLedgerApprovalMutation.isLoading}
      actions={
        <>
          <Button
            disabled={rejectLedgerApprovalMutation.isLoading}
            onClick={onClose}
          >
            {i18n.t("label.back")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={rejectLedgerApprovalMutation.isLoading}
            onClick={() => {
              rejectLedgerApprovalMutation.mutate();
            }}
          >
            {i18n.t("eventsWizard.rejectConfirmation.confirm")}
          </Button>
        </>
      }
    >
      <>
        <div>
          {i18n.t(
            isRollback
              ? "eventsWizard.rejectConfirmation.rollback.description"
              : "eventsWizard.rejectConfirmation.review.description"
          )}
        </div>
        {rejectLedgerApprovalMutation.error && (
          <FormErrorList error={rejectLedgerApprovalMutation.error} />
        )}
      </>
    </Dialog>
  );
};

export { RejectConfirmation };
