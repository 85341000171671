import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../../api/blockchain/events";
import { useRollbackDraftEventMutation } from "../../../../api/rest/company";
import { filterEvents } from "../../../../hooks/useCompanyEvents/filter-events";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import { Button } from "../../../design-system/Button";
import { Dialog } from "../../../design-system/Dialog";

type DeleteConfirmationProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  onClose: () => void;
};

const DeleteConfirmation = ({
  currentCompany,
  onSuccess,
  onClose,
}: DeleteConfirmationProps) => {
  const i18n = useTranslation();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = eventsQuery.data?.data || [];
  const latestApprovedEvent = filterEvents(events).find(
    (x) => x.status === "Approved"
  );
  const rollbackDraftEventMutation = useRollbackDraftEventMutation(
    currentCompany.orgNumber || "",
    onSuccess
  );

  return (
    <Dialog
      isOpen
      title={i18n.t("eventsWizard.quit.title")}
      onClose={onClose}
      actions={
        <>
          <Button
            isLoading={rollbackDraftEventMutation.isLoading}
            onClick={() =>
              latestApprovedEvent &&
              rollbackDraftEventMutation.mutate({
                date: latestApprovedEvent.date,
              })
            }
          >
            {i18n.t("eventsWizard.quit.delete")}
          </Button>
          <Button variant="solid" color="primary" onClick={onSuccess}>
            {i18n.t("eventsWizard.quit.save")}
          </Button>
        </>
      }
    >
      <p>{i18n.t("eventsWizard.quit.description")}</p>
    </Dialog>
  );
};

export { DeleteConfirmation };
