import type { UseTranslationResponse } from "react-i18next";

import { ShareHolderWithDilution } from "../pages/CompanyShares/ShareHolders.utils";
import { isNonEmptyArray } from "../types";
import { EntitiesMap } from "../types/models/entities";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypeClause,
  ShareTypesMap,
} from "../types/models/shares";
import { getCountryName } from "./country";
import { dateToIsoString, getFormattedDate, isoStringToDate } from "./date";
import type { ColInfo, TExcelData, TExcelRows } from "./excel";
import { formatAddress, formatPercentage, formatRefId } from "./format";

const minColLength = 10;
const cellStyle = {};
const numberCellStyle = { ...cellStyle, numFmt: "0" };
const percentageCellStyle = { ...cellStyle, numFmt: "0.00%" };

const generateShareholderData = (
  shareholders: ShareHolderWithDilution[],
  displayDiluted: boolean,
  ledger: {
    sharesByType: Record<string, number>;
    shares: number;
    votes: number;
    dilutedShares: number;
    dilutedVotes: number;
  },
  i18n: UseTranslationResponse<"translation">
): TExcelRows => {
  const sharesLabel = i18n.t("label.shares").toLowerCase();
  const sumRow = {
    [i18n.t("label.shareholder")]: i18n.t("shares.totalAllHolders"),
    [i18n.t("label.type")]: "",
    [i18n.t("label.id")]: "",
    [i18n.t("label.country")]: "",
    [i18n.t("label.address")]: "",
    ...Object.fromEntries(
      Object.entries(ledger.sharesByType).map(([key, value]) => [
        `${key} ${sharesLabel}`,
        value,
      ])
    ),
    [i18n.t("label.shares")]: ledger.shares,
    [`% ${i18n.t("label.shares").toLowerCase()}`]: formatPercentage(100 / 100),
    ...(displayDiluted && {
      [i18n.t("label.diluted.shares")]: ledger.dilutedShares,
    }),
    ...(displayDiluted && {
      [`% ${i18n.t("label.diluted.shares").toLowerCase()}`]: formatPercentage(
        100 / 100
      ),
    }),
    [i18n.t("label.votes")]: ledger.votes,
    [`% ${i18n.t("label.votes").toLowerCase()}`]: formatPercentage(100 / 100),
    ...(displayDiluted && {
      [i18n.t("label.diluted.votes")]: ledger.dilutedVotes,
    }),
    ...(displayDiluted && {
      [`% ${i18n.t("label.diluted.votes").toLowerCase()}`]: formatPercentage(
        100 / 100
      ),
    }),
    [i18n.t("label.shareholderSince")]: "",
  };
  let colInfo: ColInfo[] = Object.entries(sumRow).map(([key, value]) => ({
    wch: Math.max(key.length, (value || "").toString().length, minColLength),
  }));
  const shareholderRows: TExcelData = [];
  shareholders.forEach((shareHolder) => {
    if (!shareHolder) {
      return;
    }

    const {
      entity,
      shareTypes,
      totalShares,
      totalVotes,
      holder,
      dilutedShares,
      dilutedVotes,
    } = shareHolder;
    const row = {
      [i18n.t("label.shareholder")]: entity.name,
      [i18n.t("label.type")]: i18n.t(
        entity.type === "Private" ? "label.private" : "label.company"
      ),
      [i18n.t("label.id")]: formatRefId({
        refId: entity.refId,
        countryCode: entity.countryCode,
        birthDate: entity.birthDate,
        type: entity.type,
      }),
      [i18n.t("label.country")]: getCountryName(entity.countryCode) || "",
      [i18n.t("label.address")]: entity.contact.address
        ? formatAddress(entity.contact.address)
        : "",
      ...Object.fromEntries(
        Object.keys(ledger.sharesByType).map((key) => [
          `${key} ${sharesLabel}`,
          shareTypes[key] || 0,
        ])
      ),
      [i18n.t("label.shares")]: totalShares || 0,
      [`% ${sharesLabel}`]: `${formatPercentage(totalShares / ledger.shares)}`,
      ...(displayDiluted &&
        dilutedShares && { [i18n.t("label.diluted.shares")]: dilutedShares }),
      ...(displayDiluted &&
        dilutedShares && {
          [`% ${i18n.t("label.diluted.shares").toLowerCase()}`]:
            formatPercentage(dilutedShares / ledger.dilutedShares),
        }),
      [i18n.t("label.votes")]: totalVotes || 0,
      [`% ${i18n.t("label.votes").toLowerCase()}`]: `${formatPercentage(
        totalVotes / ledger.votes
      )}`,
      ...(displayDiluted &&
        dilutedVotes && { [i18n.t("label.diluted.votes")]: dilutedVotes }),
      ...(displayDiluted &&
        dilutedVotes && {
          [`% ${i18n.t("label.diluted.votes").toLowerCase()}`]:
            formatPercentage(dilutedVotes / ledger.dilutedVotes),
        }),
      [i18n.t("label.shareholderSince")]: isoStringToDate(holder.since) || "",
    };
    shareholderRows.push(row);
    colInfo = Object.keys(row).map((key, index) => {
      const value = row[key] ?? "";
      const valueLength = (
        value instanceof Date ? dateToIsoString(value) : value.toString()
      ).length;

      return {
        wch: Math.max(
          key.length,
          valueLength,
          colInfo[index]?.wch ?? minColLength
        ),
      };
    });
  });

  const data = [...shareholderRows, sumRow];

  return {
    data: isNonEmptyArray(data) ? data : [sumRow],
    colInfo,
    styles: [
      cellStyle,
      cellStyle,
      cellStyle,
      cellStyle,
      cellStyle,
      ...Object.keys(ledger.sharesByType).map(() => numberCellStyle),
      numberCellStyle,
      percentageCellStyle,
      numberCellStyle,
      percentageCellStyle,
      cellStyle,
    ],
  };
};

const generateShareledgerData = (
  shareblocks: Shareblock[],
  entitiesMap: EntitiesMap,
  shareTypesMap: ShareTypesMap,
  shareBlockHistory: ShareBlockHistory[],
  conditionOptions: {
    value: ShareTypeClause;
    label: string;
  }[],
  i18n: UseTranslationResponse<"translation">
): TExcelRows => {
  const totalShares = shareblocks.reduce((accumulator, block) => {
    return accumulator + block.end - block.start + 1;
  }, 0);
  const totalVotes = shareblocks.reduce((accumulator, block) => {
    const shareType = shareTypesMap[block.type];
    return (
      accumulator +
      (shareType ? shareType.voteValue * (block.end - block.start + 1) : 0)
    );
  }, 0);
  const sumRow = {
    [i18n.t("label.shareBlock")]: "",
    [i18n.t("label.shares")]: totalShares,
    [i18n.t("label.shareType")]: "",
    [i18n.t("label.votes")]: totalVotes,
    [i18n.t("label.shareholder")]: "",
    [i18n.t("label.id")]: "",
    [i18n.t("label.ownershipInfo")]: "",
    [i18n.t("label.entry")]: "",
    [i18n.t("shares.certificates")]: "",
    [i18n.t("label.creditor")]: "",
    [i18n.t("shares.restrictiveConditions")]: "",
    [i18n.t("shares.history")]: "",
    [i18n.t("label.trustees")]: "",
  };
  let colInfo: ColInfo[] = Object.entries(sumRow).map(([key, value]) => ({
    wch: Math.max(key.length, (value || "").toString().length, minColLength),
  }));
  const shareholderRows: TExcelData = [];
  shareblocks
    .filter((x) => !x.cancelled)
    .forEach((block) => {
      if (!block) {
        return;
      }
      const holder = entitiesMap[block.holder.id];
      if (!holder) {
        return;
      }
      const shareType = shareTypesMap[block.type];
      if (!shareType) {
        return;
      }

      const creditor = entitiesMap[block.creditor?.id || ""];
      const matchingHistory = shareBlockHistory.find(
        (history) =>
          (history.holder.id === block.holder.id ||
            history.holder.refId === entitiesMap[block.holder.id]?.refId) &&
          history.start === block.start &&
          history.end === block.end
      );

      const { start, end, type } = block;
      const row = {
        [i18n.t("label.shareBlock")]: `${start} - ${end}`,
        [i18n.t("label.shares")]: end - start + 1,
        [i18n.t("label.shareType")]: type,
        [i18n.t("label.votes")]: shareType.voteValue * (end - start + 1),
        [i18n.t("label.shareholder")]: holder.name,
        [i18n.t("label.id")]: formatRefId({
          refId: holder.refId,
          countryCode: holder.countryCode,
          birthDate: holder.birthDate,
          type: holder.type,
        }),
        [i18n.t("label.ownershipInfo")]: holder.contact.address
          ? formatAddress(holder.contact.address)
          : "",
        [i18n.t("label.entry")]: matchingHistory
          ? getFormattedDate(matchingHistory.date)
          : "",
        [i18n.t("shares.certificates")]: block.hasCertificateSince
          ? i18n.t("shares.certificates.issued", {
              date: getFormattedDate(block.hasCertificateSince),
            })
          : "",
        [i18n.t("label.creditor")]: creditor ? creditor.name : "",
        [i18n.t("shares.restrictiveConditions")]: Object.values(
          shareType.condition
        ).some(Boolean)
          ? conditionOptions
              .filter((option) => !!shareType.condition[option.value])
              .map((x) => x.label)
              .join(", ")
          : "",
        [i18n.t("shares.history")]:
          matchingHistory && matchingHistory.previousHolders
            ? matchingHistory.previousHolders
                .map(
                  (x) =>
                    `${entitiesMap[x.holder.id]?.name} (${getFormattedDate(
                      x.date
                    )})`
                )
                .join(", ")
            : "",
        [i18n.t("label.trustees")]:
          holder?.type === "Private" && holder?.children
            ? holder.children.map((x) => entitiesMap[x.id]?.name).join(", ")
            : "",
      };
      shareholderRows.push(row);
      colInfo = Object.keys(row).map((key, index) => {
        const value = row[key] ?? "";
        return {
          wch: Math.max(
            key.length,
            value.toString().length,
            colInfo[index]?.wch ?? minColLength
          ),
        };
      });
    });

  const data = [...shareholderRows, sumRow];

  return {
    data: isNonEmptyArray(data) ? data : [sumRow],
    colInfo,
    styles: [cellStyle],
  };
};

export { generateShareholderData, generateShareledgerData };
