import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useShareholdersQuery } from "../../../api/blockchain/company";
import { ShareHolderWithDilution } from "../../../pages/CompanyShares/ShareHolders.utils";
import {
  ShareholdersTable,
  SortBy,
} from "../../../pages/CompanyShares/ShareholdersTable/ShareholdersTable";
import { useLedgerVersions } from "../../../pages/CompanyShares/useLedgerVersions";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { EntitiesMap } from "../../../types/models/entities";
import { Shareholder, Shareholders } from "../../../types/models/shares";
import { Loading } from "../../design-system/Loading";
import { Tab } from "../../design-system/Tab";
import { getEntityWithFallback } from "../../EventList/EventsTable/EventsTable.utils";

type ShareholdersComparisonProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  entitiesMap: EntitiesMap;
};

const getShareholderWithEntity = (
  entitiesMap: EntitiesMap,
  shareholder: Shareholder
): ShareHolderWithDilution | null => {
  const entity = getEntityWithFallback(entitiesMap, shareholder.holder);

  if (!("type" in entity)) {
    console.error("No entity fallback found");

    return null;
  }

  return { ...shareholder, entity };
};

const ShareholdersComparisonTable = ({
  data,
  entitiesMap,
  sortBy,
  setSortBy,
}: {
  data?: Shareholders;
  entitiesMap: EntitiesMap;
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
}) => {
  if (!data) {
    return <Loading />;
  }

  return (
    <ShareholdersTable
      displayDiluted={false}
      shareholders={data.shareholders
        .map((i) => getShareholderWithEntity(entitiesMap, i))
        .filter((x) => x !== null)}
      totalShares={data.totalShares}
      totalVotes={data.totalVotes}
      sortBy={sortBy}
      setSortBy={setSortBy}
    />
  );
};

const ShareholdersComparison = ({
  currentCompany,
  entitiesMap,
}: ShareholdersComparisonProps) => {
  const i18n = useTranslation();
  const [sortBy, setSortBy] = useState<SortBy>("shares-desc");
  const approvedVersions = useLedgerVersions(
    currentCompany.orgNumber,
    "approved"
  );
  const shareholdersQueryDraft = useShareholdersQuery(
    currentCompany.orgNumber,
    ""
  );
  const shareholdersQueryApproved = useShareholdersQuery(
    currentCompany.orgNumber,
    approvedVersions[0]?.formatedValue
  );

  return (
    <div className="tw-rounded md:tw-border">
      {approvedVersions.length > 0 ? (
        <Tab.Group defaultIndex={1}>
          <Tab.List className="tw-px-4 tw-py-2">
            <Tab>{i18n.t("eventsWizard.shareholdersTable.before")}</Tab>
            <Tab>{i18n.t("eventsWizard.shareholdersTable.after")}</Tab>
          </Tab.List>
          <Tab.Panels>
            <hr />
            <Tab.Panel>
              <ShareholdersComparisonTable
                data={shareholdersQueryApproved.data}
                entitiesMap={entitiesMap}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ShareholdersComparisonTable
                data={shareholdersQueryDraft.data}
                entitiesMap={entitiesMap}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      ) : (
        <ShareholdersComparisonTable
          data={shareholdersQueryDraft.data}
          entitiesMap={entitiesMap}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    </div>
  );
};

export { getShareholderWithEntity, ShareholdersComparison };
