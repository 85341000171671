import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareholderBlocksQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import {
  useReduceShareCapital,
  useReduceShareCapitalCancelShares,
} from "../../../api/rest/events";
import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import { Badge } from "../../../components/design-system/Badge";
import { Description } from "../../../components/design-system/Description";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import { EntityItem } from "../../../components/design-system/EntityItem";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { List, ListItem } from "../../../components/design-system/List";
import { ListHeader } from "../../../components/design-system/ListHeader";
import { Loading } from "../../../components/design-system/Loading";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import { getEntityWithFallback } from "../../../components/EventList/EventsTable/EventsTable.utils";
import type { TEventSummaryMetric } from "../../../components/EventSummary";
import {
  EventFormWrapper,
  EventSummarySectionList,
} from "../../../components/EventSummary";
import {
  SelectMultiRange,
  ShareRangeErrors,
} from "../../../components/SelectMultiRange";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  Ledger,
  Range,
  ShareholderBlocks,
  ShareRange,
} from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import { formatNumber } from "../../../utils/format";
import {
  calcSumWithinRange,
  hasOverlappingRanges,
  multiplyShares,
  sumRanges,
  validRanges,
} from "../../../utils/shares";
import EditShareCancel from "./EditShareCancel";

type IncreaseCapitalProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

type ActionType = "basic" | "cancelspecific" | "prorata";

type ActionOption = { value: ActionType; title: string; description: string };

type FormProps = {
  date: string;
  type: ActionType;
  shareCapital: number;
  shareRanges: ShareRange[];
  prorataCancelledShares: Record<string, Range[]>;
};

const getMetrics = (formValues: FormProps, ledgerData?: Ledger) => {
  const beforeShareCapital = ledgerData?.capital || 1;
  const beforeTotalShares = ledgerData?.shares.total || 1;
  const beforeQuotaValue = beforeShareCapital / beforeTotalShares;
  if (formValues.type === "cancelspecific") {
    const cancelledShares = sumRanges(formValues.shareRanges);
    const afterTotalShares = beforeTotalShares - cancelledShares;

    return {
      shareCapital: {
        before: beforeShareCapital,
        after: beforeQuotaValue * afterTotalShares,
      },
      quotaValue: { before: beforeQuotaValue, after: beforeQuotaValue },
      numberOfShares: { before: beforeTotalShares, after: afterTotalShares },
    };
  }
  if (formValues.type === "prorata") {
    const afterShareCapital =
      beforeShareCapital - (formValues.shareCapital || 0);
    const afterTotalShares = multiplyShares(
      afterShareCapital / beforeShareCapital,
      beforeTotalShares
    );

    return {
      shareCapital: { before: beforeShareCapital, after: afterShareCapital },
      quotaValue: { before: beforeQuotaValue, after: beforeQuotaValue },
      numberOfShares: { before: beforeTotalShares, after: afterTotalShares },
    };
  }
  // basic
  const afterShareCapital = beforeShareCapital - (formValues.shareCapital || 0);

  return {
    shareCapital: { before: beforeShareCapital, after: afterShareCapital },
    quotaValue: {
      before: beforeQuotaValue,
      after: afterShareCapital / beforeTotalShares,
    },
    numberOfShares: { before: beforeTotalShares, after: beforeTotalShares },
  };
};

const getCancelledShares = (
  modifier: number,
  shareholders: ShareholderBlocks[]
) =>
  shareholders.reduce<FormProps["prorataCancelledShares"]>(
    (cancelledShares, shareholder) => {
      const { blocks, id } = shareholder;
      const sharesByType = blocks.reduce<Record<string, number>>(
        (prev, curr) => ({
          ...prev,
          [curr.type]: calcSumWithinRange(curr) + (prev[curr.type] || 0),
        }),
        {}
      );
      const sharesToCancelByType: Record<string, number> = Object.fromEntries(
        Object.keys(sharesByType).map((curr) => [
          curr,
          multiplyShares(
            1 - modifier,
            // @ts-expect-error TODO: Fix after TS migration
            sharesByType[curr]
          ),
        ])
      );
      const rangesToCancel: Range[] = [];
      for (let i = blocks.length - 1; i >= 0; i--) {
        // @ts-expect-error TS(2339) FIXME: Property 'start' does not exist on type 'Omit<TSha... Remove this comment to see the full error message
        const { start, end, type } = blocks[i];
        const sharesToCancel = sharesToCancelByType[type];
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        if (sharesToCancel > 1) {
          const blockSize = end - start + 1;
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          if (sharesToCancel >= blockSize) {
            rangesToCancel.push({ start, end });
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            const remainingSharesToCancel = sharesToCancel - blockSize;
            sharesToCancelByType[type] = remainingSharesToCancel;
          } else {
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            rangesToCancel.push({ start: end - sharesToCancel + 1, end });
            sharesToCancelByType[type] = 0;
          }
        }
      }

      return { ...cancelledShares, [id]: rangesToCancel.reverse() };
    },
    {}
  );

const formId = "edit-reducecapital-form";

const ReduceCapital = ({
  currentCompany,
  onSuccess,
  setFormData,
}: IncreaseCapitalProps) => {
  const i18n = useTranslation();

  const {
    handleSubmit,
    control,
    register,
    formState,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormProps>({
    mode: "onSubmit",
    defaultValues: {
      date: "",
      shareRanges: [{ start: null, end: null, type: new Date().toISOString() }],
      prorataCancelledShares: {},
    },
  });
  const formValues = watch();
  const lastEventDate = useLatestVersion();
  const shareHoldersQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const shareHolders = shareHoldersQuery.data || [];
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));
  const shareBlockQuery = useShareblocksQuery(currentCompany.orgNumber, "");
  const shareBlocks = shareBlockQuery.data || [];

  const manuallyAdjusted = useRef<Record<string, boolean>>({});

  const handleSuccess = () => {
    onSuccess();
  };
  const reduceShareCapitalMutation = useReduceShareCapital(
    currentCompany.orgNumber,
    { onSuccess: handleSuccess }
  );

  const reduceShareCapitalCancelSharesMutation =
    useReduceShareCapitalCancelShares(currentCompany.orgNumber, {
      onSuccess: handleSuccess,
    });

  const mutation = {
    basic: reduceShareCapitalMutation,
    cancelspecific: reduceShareCapitalCancelSharesMutation,
    prorata: reduceShareCapitalCancelSharesMutation,
  }[formValues.type || "basic"];

  useEffect(() => {
    setFormData((data) => ({ ...data, formId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  const onSubmit = (data: FormProps) => {
    if (metrics.numberOfShares.after < 1) {
      setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verficiation.reduceCaptial.noShares"),
      });
      return;
    }
    if (formValues.type === "prorata" && prorataNumberOfSharesDiff !== 0) {
      setError("prorataCancelledShares", {
        type: "manual",
        message: i18n.t("error.verification.shares.issue"),
      });
      return;
    }
    if (data.type === "cancelspecific") {
      const shareRangeErrors = data.shareRanges.map((range) => {
        const matchingBlock = shareBlocks.find(
          (x) =>
            range.start &&
            range.end &&
            x.start <= range.start &&
            x.end >= range.end
        );
        if (!matchingBlock) {
          return i18n.t("error.validation.blocks.overlaps");
        }
        if (matchingBlock.cancelled) {
          return i18n.t("error.verification.shares.cancelled");
        }
        return null;
      });
      if (shareRangeErrors.some((x) => x)) {
        for (let i = 0; i < shareRangeErrors.length; i++) {
          const error = shareRangeErrors[i];
          if (error !== null) {
            setError(`shareRanges.${i}.start`, {
              type: "manual",
              message: error,
            });
            setError(`shareRanges.${i}.end`, {
              type: "manual",
              message: error,
            });
          }
        }
        return;
      }
    }
    if (data.type === "basic") {
      reduceShareCapitalMutation.mutate({
        date: data.date,
        amount: data.shareCapital,
      });
    }
    if (data.type === "cancelspecific") {
      reduceShareCapitalCancelSharesMutation.mutate({
        date: data.date,
        shareRanges: validRanges(data.shareRanges).map((r) => ({
          start: r.start,
          end: r.end,
        })),
      });
    }
    if (data.type === "prorata") {
      reduceShareCapitalCancelSharesMutation.mutate({
        date: data.date,
        amount: data.shareCapital,
        shareRanges: Object.values(data.prorataCancelledShares).flat(),
      });
    }
  };

  const typeOptions: ActionOption[] = [
    {
      value: "basic",
      title: i18n.t("events.reduceCapital.form.type.option1.title"),
      description: i18n.t("events.reduceCapital.form.type.option1.description"),
    },
    {
      value: "prorata",
      title: i18n.t("events.reduceCapital.form.type.option2.title"),
      description: i18n.t("events.reduceCapital.form.type.option2.description"),
    },
    {
      value: "cancelspecific",
      title: i18n.t("events.reduceCapital.form.type.option3.title"),
      description: i18n.t("events.reduceCapital.form.type.option3.description"),
    },
  ];

  const metrics = getMetrics(formValues, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },

    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },

    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };

  const modifier = metrics.numberOfShares.after / metrics.numberOfShares.before;
  useEffect(() => {
    if (formValues.type === "prorata") {
      const automaticCancelledShares = getCancelledShares(
        modifier,
        shareHoldersQuery.data || []
      );
      const result = Object.entries(automaticCancelledShares).reduce<
        Record<string, Range[]>
      >((acc, [holderId, ranges]) => {
        const newShares = manuallyAdjusted.current[holderId]
          ? formValues.prorataCancelledShares[holderId] || []
          : ranges;

        return {
          ...acc,
          [holderId]: newShares,
        };
      }, {});
      setValue("prorataCancelledShares", result);
    }
  }, [
    modifier,
    formValues.type,
    setValue,
    shareHoldersQuery.data,
    formValues.prorataCancelledShares,
  ]);

  const prorataNumberOfSharesDiff =
    sumRanges(Object.values(formValues.prorataCancelledShares).flat()) -
    (metrics.numberOfShares.before - metrics.numberOfShares.after);

  const prorataCancelledSharesError = Array.isArray(
    formState.errors.prorataCancelledShares
  )
    ? formState.errors.prorataCancelledShares[0]?.message
    : formState.errors.prorataCancelledShares?.message;

  if (
    ledgerQuery.isLoading ||
    shareHoldersQuery.isLoading ||
    entitiesQuery.isLoading ||
    shareBlockQuery.isLoading
  ) {
    return <Loading />;
  }

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.reduceCapital.title")}</h4>
        </div>
      </header>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form
          className="tw-space-y-6"
          onSubmit={(event) => {
            clearErrors("prorataCancelledShares");
            handleSubmit(onSubmit)(event);
          }}
          id={formId}
        >
          <FormGroup>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
            <Controller
              control={control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value ?? ""}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                    min={
                      lastEventDate !== undefined
                        ? dateToIsoString(lastEventDate.date)
                        : undefined
                    }
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="date"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              render={({ field: { name, onChange, value }, fieldState }) => (
                <>
                  <RadioGroup
                    value={value || null}
                    onChange={onChange}
                    name={name}
                  >
                    <RadioGroup.Label>
                      <FormLabel htmlFor={name} className="tw-mb-4">
                        {i18n.t("events.reduceCapital.form.type.label")}
                      </FormLabel>
                    </RadioGroup.Label>
                    <div className="tw-space-y-2">
                      {typeOptions.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                        >
                          {({ checked }) => (
                            <RadioGroup.OptionContent checked={checked}>
                              <Description
                                title={option.title}
                                description={option.description}
                              />
                            </RadioGroup.OptionContent>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="type"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {(formValues.type === "basic" || formValues.type === "prorata") && (
            <FormGroup>
              <FormLabel htmlFor="shareCapital">
                {i18n.t("events.reduceCapital.form.shareCapital.label")}
              </FormLabel>
              <Input
                id="shareCapital"
                {...register("shareCapital", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: i18n.t("error.validation.range.min", { min: 1 }),
                  },
                  max: {
                    value: metrics.shareCapital.before,
                    message: i18n.t("error.validation.range.max", {
                      max: metrics.shareCapital.before,
                    }),
                  },
                })}
                type="number"
                step={0.01}
                prefix={currentCompany.settings?.currency}
              />
              <FormError>{formState.errors.shareCapital?.message}</FormError>
            </FormGroup>
          )}
          {formValues.type === "cancelspecific" && (
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <FormGroup>
                  <SelectMultiRange
                    existingShareBlocks={shareBlocks}
                    entities={entitiesData}
                    value={value}
                    onChange={onChange}
                    fieldKey="shareRanges"
                    register={register}
                    errors={
                      (formState.errors.shareRanges as ShareRangeErrors) || {}
                    }
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </FormGroup>
              )}
              name="shareRanges"
              rules={{
                validate: (value) => {
                  const ranges = validRanges(value);
                  if (ranges.length === 0) {
                    return i18n.t("error.validation.required");
                  }
                  const rangeTotal = sumRanges(ranges);
                  if (rangeTotal > metrics.numberOfShares.before) {
                    return i18n.t("error.validation.range.max", {
                      max: metrics.numberOfShares.before,
                    });
                  }
                  if (hasOverlappingRanges(ranges)) {
                    return i18n.t("error.validation.overlaps");
                  }

                  return true;
                },
              }}
            />
          )}
          {formValues.type === "prorata" && (
            <>
              <List header={<ListHeader title={i18n.t("label.shareBlocks")} />}>
                {shareHolders.map((holder) => {
                  const cancelledShares =
                    formValues.prorataCancelledShares[holder.id];
                  const sharesCount = sumRanges(holder.blocks);
                  const cancelledSharesCount = sumRanges(cancelledShares || []);
                  const remainingSharesCount =
                    sharesCount - cancelledSharesCount;

                  const entityItem = getEntityWithFallback(entitiesMap, holder);

                  if (!("type" in entityItem)) {
                    console.error("No entity fallback found");

                    return null;
                  }

                  return (
                    <ListItem key={holder.id}>
                      <div className="tw-flex tw-flex-col tw-gap-4">
                        <div className="tw-grid tw-gap-2 md:tw-grid-cols-2">
                          <EntityItem value={entityItem} />
                          <Description
                            title={i18n.t("shares.remaining", {
                              count: remainingSharesCount,
                            })}
                            description={i18n.t("shares.cancelled", {
                              count: cancelledSharesCount,
                            })}
                          />
                        </div>
                        <div>
                          <ul className="tw-space-y-2">
                            {holder.blocks.map((block) => {
                              const cancelledWithinRange =
                                cancelledShares?.find(
                                  (range) => range.end === block.end
                                );
                              const numberOfSharesCancelled =
                                cancelledWithinRange
                                  ? (cancelledWithinRange.end || 0) -
                                    (cancelledWithinRange.start || 0) +
                                    1
                                  : 0;

                              return (
                                <li
                                  key={block.start}
                                  className="tw-grid tw-grid-cols-4 tw-items-center tw-gap-2"
                                >
                                  <Description
                                    title={`${formatNumber(
                                      block.start
                                    )} - ${formatNumber(block.end)}`}
                                    description={formatNumber(
                                      calcSumWithinRange(block)
                                    )}
                                  />
                                  <div>
                                    <Badge>{block.type}</Badge>
                                  </div>
                                  <Description
                                    title={
                                      cancelledWithinRange ? (
                                        <span className="tw-text-error">
                                          {`${formatNumber(
                                            cancelledWithinRange.start || 0
                                          )} - ${formatNumber(
                                            cancelledWithinRange.end || 0
                                          )}`}
                                        </span>
                                      ) : (
                                        "-"
                                      )
                                    }
                                    description={i18n.t("shares.cancelled", {
                                      count: numberOfSharesCancelled,
                                    })}
                                  />
                                  {modifier < 1 && (
                                    <div className="tw-flex tw-justify-end">
                                      <EditShareCancel
                                        value={{
                                          numberOfShares:
                                            numberOfSharesCancelled,
                                        }}
                                        blockSize={calcSumWithinRange(block)}
                                        onSuccess={({
                                          numberOfShares: rawNumberOfShares = 0,
                                        }) => {
                                          const numberOfShares = Number.isNaN(
                                            rawNumberOfShares
                                          )
                                            ? 0
                                            : rawNumberOfShares;
                                          let newCancelledShares;
                                          if (cancelledWithinRange) {
                                            if (numberOfShares < 1) {
                                              newCancelledShares =
                                                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                                                cancelledShares.filter(
                                                  (range) =>
                                                    range.end !==
                                                    cancelledWithinRange.end
                                                );
                                            } else {
                                              newCancelledShares =
                                                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                                                cancelledShares.map((range) => {
                                                  if (
                                                    range.end ===
                                                    cancelledWithinRange.end
                                                  ) {
                                                    return {
                                                      end: block.end,
                                                      start:
                                                        block.end -
                                                        numberOfShares +
                                                        1,
                                                    };
                                                  }

                                                  return range;
                                                });
                                            }
                                          } else {
                                            if (numberOfShares < 1) {
                                              return;
                                            }
                                            let indexToInsert = 0;
                                            for (const [
                                              i,
                                              range,
                                            ] of cancelledShares?.entries() ??
                                              []) {
                                              if (
                                                block.end < (range.end || 0)
                                              ) {
                                                break;
                                              }
                                              indexToInsert = i;
                                            }
                                            newCancelledShares = [
                                              // @ts-expect-error TS(2488) FIXME: Type 'Range[] | undefined' must have a '[Symbol.i... Remove this comment to see the full error message
                                              ...cancelledShares,
                                            ];
                                            newCancelledShares.splice(
                                              indexToInsert,
                                              0,
                                              {
                                                end: block.end,
                                                start:
                                                  block.end -
                                                  numberOfShares +
                                                  1,
                                              }
                                            );
                                          }
                                          manuallyAdjusted.current[holder.id] =
                                            true;

                                          setValue("prorataCancelledShares", {
                                            ...formValues.prorataCancelledShares,
                                            [holder.id]: newCancelledShares,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
              <FormError>{prorataCancelledSharesError}</FormError>
              {modifier < 1 && (
                <DistributionProgress diff={prorataNumberOfSharesDiff} />
              )}
            </>
          )}
          {mutation.error && <FormErrorList error={mutation.error} />}
        </form>
      </EventFormWrapper>
    </div>
  );
};

export default ReduceCapital;
