import { useTranslation } from "react-i18next";

import { useApproveLedgerMutation } from "../../../../api/rest/company";
import { FORBIDDEN } from "../../../../api/rest/utils";
import { useSession } from "../../../../context/session";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import type { LedgerVersion } from "../../../../types/models/shares";
import { isGreaterLedgerVersion } from "../../../../utils/date";
import { hasRequiredPermission } from "../../../../utils/permissions";
import { Button } from "../../../design-system/Button";
import { Dialog } from "../../../design-system/Dialog";
import { FormErrorList } from "../../../design-system/FormGroup";

type ApproveConfirmationProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  version: { value: LedgerVersion; label: string };
  isRollback: boolean;
};

const ApproveConfirmation = ({
  currentCompany,
  version,
  isRollback,
  onClose,
  onSuccess,
}: ApproveConfirmationProps) => {
  const i18n = useTranslation();
  const { user } = useSession();

  const hasRole = hasRequiredPermission("Editor", currentCompany, user);
  const isApproved =
    !isRollback &&
    !isGreaterLedgerVersion(version.value, currentCompany.ledgerApproved);
  let validationError:
    | ""
    | "error.verification.snapshot.alreadyApproved"
    | "error.verification.unauthorized" = "";
  if (isApproved) {
    validationError = "error.verification.snapshot.alreadyApproved";
  }
  if (!hasRole) {
    validationError = "error.verification.unauthorized";
  }

  const approveLedgerMutation = useApproveLedgerMutation(
    currentCompany.orgNumber || "",
    version.value,
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );
  const serverError =
    approveLedgerMutation.error ||
    (validationError
      ? { status: FORBIDDEN, errors: [{ message: { code: validationError } }] }
      : null);
  const { isLoading } = approveLedgerMutation;

  return (
    <Dialog
      isOpen
      title={i18n.t(
        isRollback
          ? "eventsWizard.approveConfirmation.rollback.title"
          : "eventsWizard.approveConfirmation.review.title"
      )}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            disabled={!!validationError}
            onClick={() => {
              approveLedgerMutation.mutate();
            }}
          >
            {i18n.t("label.approve")}
          </Button>
        </>
      }
    >
      {serverError && <FormErrorList error={serverError} />}
    </Dialog>
  );
};

export { ApproveConfirmation };
