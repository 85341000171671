import { encryption } from "postchain-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Truid from "../../api/rest/auth/Truid";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import { notify } from "../../components/design-system/Notifications";
import { TruidAuthDetails } from "../../types/models/auth";
import isMobileDevice from "../../utils/isMobileDevice";
import { AuthMethodProps } from "..";
import { getKeyPairFromStorage, setKeyPairInStorage } from "../utils";

const TruidLogin = (props: AuthMethodProps) => {
  const [TruidLoginState, setTruidLoginState] = useState<TruidAuthDetails>();
  const i18n = useTranslation();

  const initTruidAuthMutation = Truid.useInitAuthMutation({
    onSuccess: (data) => {
      setTruidLoginState(data);
    },
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const collectTruidAuthMutation = Truid.useCollectAuthMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "success") {
        props.onSuccess(data);
      }
    },
    onError: () => {
      setTruidLoginState(undefined);
    },
  });

  const onCollectTruidAuth = (code: string, state: string) => {
    const pubKey =
      getKeyPairFromStorage("pendingKeyPair")?.pubKey.toString("hex") ?? "";
    return collectTruidAuthMutation.mutate({
      code,
      state,
      pubKey,
    });
  };

  useEffect(() => {
    const TruidCallback = (event: MessageEvent) => {
      const payload = event.data;
      if (!payload || event.origin !== window.location.origin) {
        return;
      }

      if (payload.type === "TruidResponse") {
        onCollectTruidAuth(payload.code ?? payload.error, payload.state);
      }
    };

    window.addEventListener("message", TruidCallback);
    return () => {
      window.removeEventListener("message", TruidCallback);
    };
  }, [onCollectTruidAuth]);

  useEffect(() => {
    const url = TruidLoginState?.authorizationUrl;
    const isMobile = isMobileDevice();
    if (isMobile && url && url.length > 0) {
      window.location.href = url;
    }
  }, [TruidLoginState?.authorizationUrl]);

  const onInit = () => {
    initTruidAuthMutation.reset();
    collectTruidAuthMutation.reset();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initTruidAuthMutation.mutate(newKeyPair.pubKey.toString("hex"));
  };

  // On load
  useEffect(() => onInit(), []);

  const isTruidRequestError =
    initTruidAuthMutation?.isError || collectTruidAuthMutation?.isError;

  const truiIdError = initTruidAuthMutation?.isError
    ? initTruidAuthMutation.error
    : collectTruidAuthMutation?.isError
    ? collectTruidAuthMutation.error
    : null;

  if (initTruidAuthMutation?.isLoading || collectTruidAuthMutation?.isLoading) {
    return <Loading />;
  }

  return (
    <>
      {TruidLoginState && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
          <iframe
            referrerPolicy="no-referrer"
            title="Truid"
            src={TruidLoginState.authorizationUrl}
            height={500}
            width={400}
          />
          <Button
            onClick={() => {
              initTruidAuthMutation?.reset();
              collectTruidAuthMutation?.reset();
              props.onCancel();
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.cancel")}
          </Button>
        </div>
      )}
      {isTruidRequestError && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
          {truiIdError?.status === 403
            ? i18n.t("auth.Truid.loginFailed.useBankId")
            : truiIdError?.status === 401
            ? i18n.t("auth.Truid.notEnrolled")
            : i18n.t("auth.Truid.loginFailed")}

          <Button
            onClick={() => {
              initTruidAuthMutation?.reset();
              collectTruidAuthMutation?.reset();
              props.onCancel();
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.return")}
          </Button>
        </div>
      )}
    </>
  );
};

export default TruidLogin;
