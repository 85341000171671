import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { NewShareBlock } from "../../../types/models/draft";
import { Button, Color, Variant } from "../../design-system/Button";
import { PlusIcon } from "../../design-system/icons";
import { EditShareBlockDialog } from "../Dialog";

type AddShareBlockProps = {
  onSuccess: (data: NewShareBlock) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: ShareType[];
  disableNewEntity?: boolean;
  variant?: Variant;
  color?: Color;
};

const AddShareBlock = ({
  onSuccess,
  entitiesQuery,
  currentCompany,
  shareTypes,
  disableNewEntity,
  variant = "solid",
  color = "primary",
}: AddShareBlockProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();

  return (
    <>
      <Button
        variant={variant}
        color={color}
        onClick={() => setOpen(true)}
        prefix={<PlusIcon />}
      >
        {i18n.t("onboarding.action.addShareBlock")}
      </Button>
      {isOpen && (
        <EditShareBlockDialog
          title={i18n.t("onboarding.action.addShareBlock")}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          entitiesQuery={entitiesQuery}
          currentCompany={currentCompany}
          shareTypes={shareTypes}
          disableNewEntity={disableNewEntity}
          defaultValues={{ type: shareTypes[0]?.name || "" }}
        />
      )}
    </>
  );
};

export { AddShareBlock };
