import { i18n } from "../i18n";
import type {
  LedgerApprovalEvent,
  ParentEventType,
  TParentEvent,
} from "../types/models/events";
import type { LedgerVersion } from "../types/models/shares";

type ValueLabelPair = {
  value: LedgerVersion;
  label: string;
};

const EVENT_LABELS: Record<ParentEventType, string> = {
  LedgerRollback: i18n.t("events.ledgerRollback.title"),
  CompanyFoundation: i18n.t("events.companyFoundation.title"),
  ShareIssue: i18n.t("events.issue.title"),
  ReclassifySharesByRange: i18n.t("events.reclassifySharesByRange.title"),
  ReclassifySharesByClass: i18n.t("events.reclassifySharesByClass.title"),
  LedgerApproval: i18n.t("events.ledgerApproval.title"),
  LedgerApprovalInitialized: i18n.t("events.ledgerApprovalInitialized.title"),
  DecreaseCapital: i18n.t("events.reduceCapital.title"),
  DecreaseCapitalCancelShares: i18n.t(
    "events.decreaseCapitalCancelShares.title"
  ),
  IncreaseCapital: i18n.t("events.increaseCapital.title"),
  IncreaseCapitalBonusIssue: i18n.t("events.increaseCapital.title"),
  ShareSplit: i18n.t("events.split.title"),
  ReverseShareSplit: i18n.t("events.reverseSplit.title"),
  ShareTransfer: i18n.t("events.transfer.title"),
  ShareClassUpdate: i18n.t("events.shareTypeUpdate.title"),
  ShareCertificateUpdate: i18n.t("events.shareCertificateUpdate.title"),
  SharePledgedUpdate: i18n.t("events.pledgedSharesUpdate.title"),
  LedgerPolicyApproval: i18n.t("events.unknown"),
  LedgerRollbackPending: i18n.t("events.ledgerRollback.title"),
  LedgerRollbackRejected: i18n.t("events.unknown"),
  LedgerApprovalRejected: i18n.t("events.unknown"),
};

const formatEventLabel = (event: TParentEvent, eventCount?: number): string => {
  const [date] = event.date.split(".");
  const isReverseSplit =
    event.type === "ShareSplit" && event.ratio.x - event.ratio.y > 0;
  const eventLabel = isReverseSplit
    ? EVENT_LABELS.ReverseShareSplit
    : EVENT_LABELS[event.type];

  const count =
    typeof eventCount === "number" && eventCount >= 2 ? ` ${eventCount}` : "";

  return `${date}: ${eventLabel}${count}`;
};

const getEventValueLabelPair = (event?: TParentEvent): ValueLabelPair => {
  if (!event) {
    console.error("Event is undefined");

    return {
      value: "UNKNOWN.EVENT",
      label: "UNKNOWN_EVENT",
    };
  }

  return {
    value: event.date,
    label: formatEventLabel(event),
  };
};

const getEventsWithApproval = <T extends TParentEvent>(
  events: T[]
): (T & { approval?: LedgerApprovalEvent })[] => {
  const approvalEvents = events.filter((e) => e.type === "LedgerApproval");
  approvalEvents.reverse();
  return events.map((event) => {
    const approval = approvalEvents.find(
      (a) => isAfterVersion(a.date, event.date) || event.date === a.date
    ) as LedgerApprovalEvent | undefined;

    return {
      ...event,
      approval,
    };
  });
};

const getEventsWithLabel = <T extends TParentEvent>(
  events: T[]
): (T & { label: string })[] => {
  // First group events by date, then generate labels for each event per date
  const groupedByDate = events.reduce<Record<string, T[]>>((acc, event) => {
    const [date] = event.date.split(".");

    return date === undefined
      ? acc
      : { ...acc, [date]: [...(acc[date] ?? []), event] };
  }, {});

  return Object.values(groupedByDate).flatMap((dateEvents) => {
    // Count events of each type for this date
    const typeCount: Record<string, number> = dateEvents.reduce<
      Record<string, number>
    >(
      (counts, event) => ({
        ...counts,
        [event.type]: (counts[event.type] ?? 0) + 1,
      }),
      {}
    );

    // Generate labels for this date
    return dateEvents.map((event) => ({
      ...event,
      label: formatEventLabel(event, typeCount[event.type]!--),
    }));
  });
};

const isApprovalEvent = (event: TParentEvent): boolean =>
  ["LedgerApprovalInitialized", "LedgerApproval"].includes(event.type);

const isAfterVersion = (
  version: LedgerVersion,
  targetVersion: LedgerVersion | "full"
): boolean => {
  if (targetVersion === "full") {
    return true;
  }

  const [versionDateRaw, versionNumber] = version.split(".");
  const [targetDateRaw, targetNumber] = targetVersion.split(".");

  const versionDate = new Date(versionDateRaw!);
  const targetDate = new Date(targetDateRaw!);

  if (versionDate > targetDate) {
    return true;
  }

  if (versionDateRaw === targetDateRaw) {
    return parseInt(versionNumber!, 10) > parseInt(targetNumber!, 10);
  }

  return false;
};

const isBeforeOrSameVersion = (
  version: LedgerVersion,
  targetVersion: LedgerVersion | "full"
): boolean => {
  if (version === targetVersion) {
    return true;
  }

  return !isAfterVersion(version, targetVersion);
};

export type { ValueLabelPair };
export {
  formatEventLabel,
  getEventsWithApproval,
  getEventsWithLabel,
  getEventValueLabelPair,
  isAfterVersion,
  isApprovalEvent,
  isBeforeOrSameVersion,
};
