import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useActiveApprovalRuleQuery } from "../../../../../api/blockchain/company";
import { useParentEventsQuery } from "../../../../../api/blockchain/events";
import { useApprovalRuleProposalQuery } from "../../../../../api/blockchain/users";
import { ApprovalRule } from "../../../../../api/rest/approval-rule-policy";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { Badge } from "../../../../../components/design-system/Badge";
import { Button } from "../../../../../components/design-system/Button";
import { Loading } from "../../../../../components/design-system/Loading";
import { TooltipV2 } from "../../../../../components/design-system/Tooltip/TooltipV2";
import { useSession } from "../../../../../context/session";
import { useCompanyEvents } from "../../../../../hooks/useCompanyEvents";
import { getLocale } from "../../../../../i18n";
import { APP_ROUTE } from "../../../../../routes/constants";
import type { CompanyInformation } from "../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { hasRequiredPermission } from "../../../../../utils/permissions";

const useCurrentApprovalRuleDescription = (approvalRule?: ApprovalRule) => {
  const i18n = useTranslation();

  if (!approvalRule) {
    return undefined;
  }

  if (approvalRule?.rule === "BoardPercentage") {
    const percentage = approvalRule.percentage / 10;
    if (percentage === 100) {
      return [
        i18n.t("initiatePolicy.allTitle"),
        i18n.t("initiatePolicy.allDescription"),
      ];
    }
    if (percentage === 0) {
      return [
        i18n.t("initiatePolicy.anyTitle"),
        i18n.t("initiatePolicy.anyDescription"),
      ];
    }
    return [
      i18n.t("initiatePolicy.percentageTitle", {
        percentage,
      }),
      i18n.t("initiatePolicy.percentageDescription"),
    ];
  }

  if (approvalRule?.rule === "SpecificUsers") {
    return [
      i18n.t("initiatePolicy.manualTitle"),
      i18n.t("initiatePolicy.manualDescription"),
    ];
  }

  return undefined;
};

const PolicySection = ({
  currentCompany,
}: {
  currentCompany: CompanyInvolvement | CompanyInformation;
}) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const isEditor = hasRequiredPermission("Editor", currentCompany, user);
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const { pendingEvents, pendingRollbackEvents } = useCompanyEvents(
    currentCompany.orgNumber
  );
  const inReview = pendingEvents.length > 0 || pendingRollbackEvents.length > 0;
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entities = entitiesQuery.data || [];
  const policyApproval = (eventsQuery.data?.data || []).find(
    (e) => e.type === "LedgerPolicyApproval"
  );
  const activeApprovalRule = useActiveApprovalRuleQuery(
    currentCompany?.orgNumber || ""
  );
  const approvalRuleProposal = useApprovalRuleProposalQuery(
    currentCompany?.orgNumber || ""
  );
  const approvalRuleDescription = useCurrentApprovalRuleDescription(
    activeApprovalRule.data
  );
  const pendingApprovalByUser =
    approvalRuleProposal.data &&
    user &&
    approvalRuleProposal.data.pendingApprovalBy
      .map(({ id }) => id)
      .includes(user.id);
  const proposalCreatedBy =
    approvalRuleProposal.data &&
    entities.find((x) => x.id === approvalRuleProposal.data?.initiatedBy?.id);
  const approverIds =
    activeApprovalRule.data?.rule === "SpecificUsers"
      ? activeApprovalRule.data.users
          ?.map((u) => u.id?.toString())
          .filter(Boolean)
      : undefined;
  const approversNames =
    (approverIds || []).map((approverId) => {
      const entityInfo = entities.find((entity) => entity.id === approverId);
      return entityInfo?.name ?? approverId;
    }) ?? [];

  if (
    activeApprovalRule.isLoading ||
    approvalRuleProposal.isLoading ||
    entitiesQuery.isLoading ||
    eventsQuery.isLoading
  ) {
    return <Loading />;
  }

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div>
            <h2 className="tw-text-xl tw-font-medium">
              {i18n.t("settings.policy.heading")}
            </h2>
          </div>
          {approvalRuleDescription ? (
            <div>
              <p className="tw-text-sm tw-font-medium tw-text-primary">
                {approvalRuleDescription[0]}
              </p>
              <p className="tw-text-sm tw-text-secondary">
                {approvalRuleDescription[1]}
              </p>
            </div>
          ) : (
            <p className="tw-text-sm tw-text-secondary">
              <Trans
                i18nKey="settings.shareLedger.emptyState.description"
                components={{
                  aktiebolagsLagLink: (
                    <Link
                      className="tw-underline"
                      to="https://www.riksdagen.se/sv/dokument-och-lagar/dokument/svensk-forfattningssamling/aktiebolagslag-2005551_sfs-2005-551/#K5"
                      target="_blank"
                      rel="noreferrer noopener"
                    />
                  ),
                }}
              />
            </p>
          )}
          {policyApproval && (
            <p className="tw-text-sm tw-text-secondary">
              {i18n.t("settings.policy.date", {
                date: new Date(
                  policyApproval?.created || ""
                ).toLocaleDateString(getLocale()),
              })}
            </p>
          )}
          {approversNames.length > 0 && (
            <div className="tw-flex tw-flex-col tw-gap-1">
              <p className="tw-text-sm tw-text-secondary">
                {i18n.t("settings.policy.manualApprovers")}
              </p>
              <div className="tw-flex tw-flex-wrap tw-gap-2">
                {approversNames.map((name) => (
                  <Badge color="blue" key={name}>
                    {name}
                  </Badge>
                ))}
              </div>
            </div>
          )}
        </div>
        {approvalRuleProposal.data && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-border tw-bg-neutral-50 tw-p-4">
            <div className="tw-flex tw-flex-col-reverse tw-justify-between tw-gap-2 md:tw-flex-row md:tw-gap-6">
              <div>
                <h3 className="tw-text-base tw-font-medium">
                  {i18n.t("settings.policy.new.heading")}
                </h3>
                <p className="tw-text-sm tw-text-secondary">
                  {i18n.t("settings.policy.new.description", {
                    name: proposalCreatedBy?.name,
                  })}
                </p>
              </div>
              <div>
                <Badge color="warning">
                  {i18n.t("settings.policy.new.badge")}
                </Badge>
              </div>
            </div>
            <Link
              to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/policy/preview-proposal`}
              className="tw-w-fit"
            >
              {pendingApprovalByUser ? (
                <Button
                  color="primary"
                  variant="solid"
                  className="tw-flex tw-gap-2"
                >
                  {i18n.t("label.previewAndApprove")}
                </Button>
              ) : (
                <Button>{i18n.t("label.preview")}</Button>
              )}
            </Link>
          </div>
        )}
        {!approvalRuleProposal.data && isEditor && (
          <Link
            to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/policy/change`}
            className="tw-w-fit"
          >
            {!approvalRuleDescription ? (
              <Button color="primary" variant="solid">
                {i18n.t("label.setPolicy")}
              </Button>
            ) : (
              <TooltipV2
                content={
                  inReview
                    ? i18n.t("approvalPolicy.ledgerApprovalRequired")
                    : undefined
                }
              >
                <Button color="secondary" variant="outline" disabled={inReview}>
                  {i18n.t("label.changePolicy")}
                </Button>
              </TooltipV2>
            )}
          </Link>
        )}
      </div>
    </>
  );
};

export { PolicySection };
