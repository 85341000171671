import { useTranslation } from "react-i18next";

import { useRollbackDraftEventMutation } from "../../api/rest/company";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { LedgerVersion } from "../../types/models/shares";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";
import { FormErrorList } from "../design-system/FormGroup";

type DeleteDraftEventProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  version: LedgerVersion;
};

const DeleteDraftEvent = ({
  onSuccess,
  onClose,
  currentCompany,
  version,
}: DeleteDraftEventProps) => {
  const i18n = useTranslation();

  const rollbackDraftEventMutation = useRollbackDraftEventMutation(
    currentCompany?.orgNumber || "",
    onSuccess
  );

  return (
    <Dialog
      isOpen
      title={i18n.t("eventsWizard.delete.title")}
      onClose={onClose}
      isLoading={rollbackDraftEventMutation.isLoading}
      actions={
        <>
          <Button
            disabled={rollbackDraftEventMutation.isLoading}
            onClick={onClose}
          >
            {i18n.t("label.cancel")}
          </Button>
          <Button
            variant="solid"
            color="primary"
            isLoading={rollbackDraftEventMutation.isLoading}
            onClick={() => {
              rollbackDraftEventMutation.mutate({
                date: version,
              });
            }}
          >
            {i18n.t("eventsWizard.delete.confirm")}
          </Button>
        </>
      }
    >
      <div className="tw-space-y-4">
        {rollbackDraftEventMutation.error && (
          <FormErrorList error={rollbackDraftEventMutation.error} />
        )}
      </div>
    </Dialog>
  );
};

export { DeleteDraftEvent };
