import {
  ParentEventWithStatus,
  useParentEventsQuery,
} from "../../api/blockchain/events";
import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { LedgerApprovalEvent } from "../../types/models/events";
import {
  getEventsWithApproval,
  getEventsWithLabel,
} from "../../utils/events-utils";
import { filterEvents } from "./filter-events";
import { splitEvents } from "./split-events";

type CompanyEvent = ParentEventWithStatus & {
  label: string;
  approval?: LedgerApprovalEvent;
};

type CompanyEvents = {
  draftEvents: CompanyEvent[];
  pendingEvents: CompanyEvent[];
  pendingRollbackEvents: CompanyEvent[];
  approvedEvents: CompanyEvent[];
  isSuccess: boolean;
};

const useCompanyEvents = (orgNumber?: string): CompanyEvents => {
  const company = useCurrentCompany(orgNumber);
  const { user } = useSession();
  const eventsQuery = useParentEventsQuery({
    orgNumber: company?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const eventsWithApproval = getEventsWithApproval(
    eventsQuery.data?.data ?? []
  );
  const events = filterEvents(eventsWithApproval);
  const labelledEvents = getEventsWithLabel(events);
  const eventsByStatus = splitEvents(labelledEvents);

  const { approvedEvents } = eventsByStatus;
  const userRole = (company as CompanyInvolvement)?.access;
  const supportRole =
    user?.role === "Administrator"
      ? (company as CompanyInformation)?.supportRole
      : null;

  const isViewer =
    userRole === "Viewer" || (!userRole && supportRole === "Viewer");

  if (isViewer) {
    return {
      draftEvents: [],
      pendingEvents: [],
      pendingRollbackEvents: [],
      approvedEvents,
      isSuccess: eventsQuery.isSuccess,
    };
  }

  return {
    ...eventsByStatus,
    isSuccess: eventsQuery.isSuccess,
  };
};

export { useCompanyEvents };
export type { CompanyEvent, CompanyEvents };
