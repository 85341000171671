import { TParentEvent } from "../../types/models/events";

const eventsToIgnore = [
  "LedgerRollback",
  "LedgerApprovalInitialized",
  "LedgerApproval",
  "LedgerApprovalRejected",
  "LedgerRollbackRejected",
  "LedgerRollbackPending",
  "LedgerPolicyApproval",
];

const filterEvents = <T extends TParentEvent>(events: T[]) => {
  return events.filter((e) => !eventsToIgnore.includes(e.type));
};

export { filterEvents };
