import { useTranslation } from "react-i18next";

import type { Entity } from "../../../types/models/entities";
import type {
  EventType,
  ShareBlockHistory,
  ShareSplitDetails,
} from "../../../types/models/shares";
import { getFormattedDate } from "../../../utils/date";
import { formatAddress } from "../../../utils/format";
import { clsxm } from "../../../utils/tailwind";
import { EntityItem } from "../../design-system/EntityItem";
import { VerifiedEmail } from "../../VerifiedEmail";

const defaultDisplay = {
  address: true,
  email: false,
  phone: false,
  since: false,
  entry: true,
  keyFigures: true,
  certificates: true,
  creditor: true,
  restrictiveConditions: true,
  history: true,
  trustees: true,
};

type DisplayProperties = Record<keyof typeof defaultDisplay, boolean>;

type EntityRowProps = {
  entity: Entity;
  display: DisplayProperties;
};

type SectionProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

const EntityDetails = ({ entity, display }: EntityRowProps) => {
  const address =
    display.address && entity.contact.address
      ? formatAddress(entity.contact.address)
      : "";
  const displayPhone = display.phone && entity.contact.phone;
  const displayEmail = display.email && entity.contact.email;

  return (
    <div>
      <p>{address}</p>
      {displayEmail && (
        <p>
          {entity.contact.email}
          <VerifiedEmail emailVerification={entity.emailVerification} />
        </p>
      )}
      {displayPhone && <p>{entity.contact.phone}</p>}
    </div>
  );
};

const EntityRow = ({ entity, display }: EntityRowProps) => {
  return (
    <div className="tw-grid tw-gap-2 print:tw-grid-cols-3 md:tw-grid-cols-1">
      <div className="tw-col-span-1">
        <EntityItem value={entity} hasFlag={false} />
      </div>
      <div className="tw-col-span-1 tw-text-sm">
        <EntityDetails entity={entity} display={display} />
      </div>
    </div>
  );
};

const Section = ({ header = "", children, className }: SectionProps) => (
  <div
    className={clsxm(
      "tw-grid tw-gap-1 tw-py-2 tw-text-sm lg:tw-grid-cols-3",
      className
    )}
  >
    {header === "" ? null : <div className="tw-font-medium">{header}</div>}
    <div className="lg:tw-col-span-3">{children}</div>
  </div>
);

const Entry = ({ history }: { history: ShareBlockHistory }) => {
  const eventType = useEventTypeFormatted(
    history.eventType,
    history.eventDetails
  );

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <span className="tw-font-medium tw-text-neutral-800">
        {getFormattedDate(history.date)}
      </span>
      <span className="tw-text-secondary">{eventType}</span>
    </div>
  );
};

const useEventTypeFormatted = (
  eventType: EventType,
  eventDetails?: ShareSplitDetails
) => {
  const i18n = useTranslation();
  switch (eventType) {
    case "CompanyFoundation":
      return i18n.t("events.companyFoundation.title");
    case "ShareIssue":
      return i18n.t("events.issue.title");
    case "ShareSplit":
      return eventDetails
        ? eventDetails.originShareRanges &&
          eventDetails.originShareRanges.length > 0
          ? i18n.t("events.split.title.detailed", {
              ratioX: eventDetails.ratioX,
              ratioY: eventDetails.ratioY,
              shareBlocks: eventDetails.originShareRanges
                .map(({ start, end }) => `${start}-${end}`)
                .join(", "),
            })
          : i18n.t("events.split.title.ratio", {
              ratioX: eventDetails.ratioX,
              ratioY: eventDetails.ratioY,
            })
        : i18n.t("events.split.title");
    case "ReverseShareSplit":
      return i18n.t("events.reverseSplit.title");
    case "ShareTransfer":
      return i18n.t("events.transfer.title");
    case "IncreaseCapitalBonusIssue":
      return i18n.t("events.bonusIssue.title");
    case "DecreaseCapitalCancelShares":
      return i18n.t("events.decreaseCapitalCancelShares.title");
    case "ReclassifySharesByClass":
      return i18n.t("events.reclassification.title");
    case "ReclassifySharesByRange":
      return i18n.t("events.reclassification.title");
    default:
      return "";
  }
};

export {
  defaultDisplay,
  EntityDetails,
  EntityRow,
  Entry,
  Section,
  useEventTypeFormatted,
};
export type { DisplayProperties as TDisplayProperties };
