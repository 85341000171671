import { z } from "zod";

import type { TDraftShareBlock } from "./draft";

type ShareOwnership = { amount: number; type: string };

const ConditionSchema = z.object({
  consent: z.boolean(),
  preemption: z.boolean(),
  offerOfFirstRefusal: z.boolean(),
  conversion: z.boolean(),
  redemption: z.boolean(),
});

type Condition = z.infer<typeof ConditionSchema>;

const ShareTypeSchema = z.object({
  voteValue: z.number(),
  name: z.string(),
  condition: ConditionSchema,
  isUsed: z.boolean().optional(),
});

type ShareType = z.infer<typeof ShareTypeSchema>;

type ShareTypeClause = keyof Condition;

type ShareTypesMap = Record<string, ShareType>;

type ShareBlockEntity = {
  id: string;
  refId: string;
  type: "Private" | "Company";
  since: string;
  unmaskedRefId?: string;
};

const EventTypeSchema = z.enum([
  "CompanyFoundation",
  "ShareIssue",
  "ShareSplit",
  "ReverseShareSplit",
  "ShareTransfer",
  "LedgerApprovalInitialized",
  "LedgerApproval",
  "LedgerRollback",
  "IncreaseCapital",
  "IncreaseCapitalBonusIssue",
  "DecreaseCapital",
  "DecreaseCapitalCancelShares",
  "ReclassifySharesByClass",
  "ReclassifySharesByRange",
  "ShareClassUpdate",
  "ShareCertificateUpdate",
  "SharePledgedUpdate",
  "LedgerPolicyApproval",
  "LedgerConfirmedAsOfficial",
  "LedgerRollbackPending",
  "LedgerRollbackRejected",
]);
type EventType = z.infer<typeof EventTypeSchema>;

type ShareSplitDetails = {
  originShareRanges?: { start: number; end: number }[];
  ratioX: number;
  ratioY: number;
};

type ShareBlockHistory = {
  previousHolders?: ShareBlockHistory[];
  start: number;
  end: number;
  eventType: EventType;
  eventDetails?: ShareSplitDetails;
  holder: {
    id: string;
    refId: string;
    name: string;
  };
  cancelled: boolean;
  date: string;
};

type Shareblock = {
  start: number;
  end: number;
  holder: ShareBlockEntity;
  type: ShareType["name"];
  created: LedgerVersion;
  lastModified: LedgerVersion;
  cancelled: boolean;
  hasCertificateSince: string;
  creditor?: ShareBlockEntity;
  history?: ShareBlockHistory[];
};

type Shareholder = {
  totalShares: number;
  totalVotes: number;
  shareTypes: { [name: string]: number };
  holder: ShareBlockEntity;
};

type ShareholderRaw = {
  totalShares: number;
  totalVotes: string;
  shareTypes: { [name: string]: number };
  holder: ShareBlockEntity;
};

type Shareholders = {
  totalShares: number;
  totalVotes: number;
  shareholders: Shareholder[];
};

type ShareholdersRaw = {
  totalShares: number;
  totalVotes: string;
  shareholders: ShareholderRaw[];
};

type ShareTypeWithShares = ShareType & { shares: number };

const ApprovalRuleTypeSchema = z.enum(["BoardPercentage", "SpecificUsers"]);
const ApprovalRuleTypeDefaultSchema = z.enum(["None"]);
type ApprovalRuleType = z.infer<typeof ApprovalRuleTypeSchema>;

const UserSchema = z.object({ id: z.string() });
type User = z.infer<typeof UserSchema>;

const CompanyStatusSchema = z.enum(["Onboarding", "Onboarded", "Inactive"]);
type CompanyStatus = z.infer<typeof CompanyStatusSchema>;

const ApprovalStatusSchema = z.enum(["Pending", "Approved"]);

const BoardMemberApprovalInfoSchema = z.object({
  rule: z.literal(ApprovalRuleTypeSchema.Values.BoardPercentage),
  approvedBy: z.array(UserSchema),
  pendingApprovalBy: z.array(UserSchema),
  requiredNumber: z.number(),
  currentNumber: z.number(),
  status: ApprovalStatusSchema,
});

const ManualApprovalInfoSchema = z.object({
  rule: z.literal(ApprovalRuleTypeSchema.Values.SpecificUsers),
  approvedBy: z.array(UserSchema),
  pendingApprovalBy: z.array(UserSchema),
  status: ApprovalStatusSchema,
});

const ApprovalInfoSchema = z.union([
  BoardMemberApprovalInfoSchema,
  ManualApprovalInfoSchema,
]);

const ApprovalInfoResponseSchema = z
  .union([
    ApprovalInfoSchema,
    z.object({
      rule: ApprovalRuleTypeDefaultSchema,
    }),
  ])
  .or(z.null());

type ApprovalInfo = z.infer<typeof ApprovalInfoSchema>;

type ApprovalInfoResponse = z.infer<typeof ApprovalInfoResponseSchema>;

type ApprovalUpdate = {
  rule: ApprovalRuleType;
  requiredNumber: number;
  approvers: User[];
};

type ShareholderBlocks = ShareBlockEntity & {
  blocks: Omit<Shareblock, "holder">[];
};

// posting to rest and used in forms

type ReclassificationClass = {
  date: string;
  fromClass: string;
  toClass: { name: string; voteValue?: number };
};

type ReclassificationRange = {
  date: string;
  shareRanges: Range[];
  toClass: { name: string; voteValue?: number };
};

type SharesTransfer = {
  date: string;
  holderIdentifier: string;
  targetIdentifier: string;
  type: string;
  amount: number;
};

type SharesTransferRange = {
  date: string;
  targetIdentifier: string;
  shareRanges: Range[];
};

type ShareCapitalChange = { date: string; amount: number };

const RangeSchema = z.object({
  start: z.number().nullish(),
  end: z.number().nullish(),
});

type Range = z.infer<typeof RangeSchema>;

const ShareRangeSchema = RangeSchema.extend({
  type: z.string(),
});

type ShareRange = z.infer<typeof ShareRangeSchema>;

type SharesReductionCancelShares = {
  date: string;
  amount?: number;
  shareRanges: Range[];
};

type ShareCapitalIncreaseBonusIssue = {
  date: string;
  amount: number;
  shareRanges: TDraftShareBlock[];
};

type ReverseShareSplit = {
  date: string;
  ratio: { x: number; y: number };
};

type ShareSplit = ReverseShareSplit & {
  shareRanges: TDraftShareBlock[];
};

type ShareTypeUpdate = {
  date: string;
  condition: Condition;
};

type ShareCertificatesUpdate = {
  date: string;
  shareRanges: Required<Range>[];
};

type IRangeWithCreditor = Range & { creditorId?: string };

type CreditorRow = ShareRange & { creditorId?: string };
type PledgedSharesUpdate = {
  date: string;
  shareRanges: IRangeWithCreditor[];
};

type DateVersion = {
  date: Date;
  version: string;
  formatedValue: LedgerVersion;
  isApproved?: boolean;
};

const LedgerVersionSchema = z.custom<`${string}.${string}`>((val) => {
  const str = String(val);
  const parts = str.split(".");
  const hasExactlyTwoParts = parts.length === 2;

  return hasExactlyTwoParts;
});

type LedgerVersion = z.infer<typeof LedgerVersionSchema>;

type Ledger = {
  capital: number;
  shares: { total: number; lastNumber: number };
};

export {
  ApprovalInfoResponseSchema,
  ConditionSchema,
  LedgerVersionSchema,
  ShareRangeSchema,
  ShareTypeSchema,
  UserSchema,
};
export type {
  ApprovalInfo,
  ApprovalInfoResponse,
  ApprovalRuleType,
  ApprovalUpdate,
  CompanyStatus,
  Condition,
  CreditorRow,
  DateVersion,
  EventType,
  Ledger,
  LedgerVersion,
  PledgedSharesUpdate,
  Range,
  ReclassificationClass,
  ReclassificationRange,
  ReverseShareSplit,
  Shareblock,
  ShareBlockHistory,
  ShareCapitalChange,
  ShareCapitalIncreaseBonusIssue,
  ShareCertificatesUpdate,
  Shareholder,
  ShareholderBlocks,
  Shareholders,
  ShareholdersRaw,
  ShareOwnership,
  ShareRange,
  ShareSplit,
  ShareSplitDetails,
  SharesReductionCancelShares,
  SharesTransfer,
  SharesTransferRange,
  ShareType,
  ShareTypeClause,
  ShareTypesMap,
  ShareTypeUpdate,
  ShareTypeWithShares,
  User,
};
