import { useTranslation } from "react-i18next";

import { useRestoreLedgerMutation } from "../../api/rest/company";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { LedgerVersion } from "../../types/models/shares";
import { type ValueLabelPair } from "../../utils/events-utils";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";
import { FormErrorList } from "../design-system/FormGroup";

type RestoreSharesProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  currentVersion: LedgerVersion;
  version?: ValueLabelPair;
};

const RestoreShares = ({
  currentCompany,
  currentVersion,
  version,
  onClose,
  onSuccess,
}: RestoreSharesProps) => {
  const i18n = useTranslation();

  const restoreLedgerMutation = useRestoreLedgerMutation(
    currentCompany.orgNumber || "",
    currentVersion,
    version ? version.value : version,
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );
  const { isLoading, mutate } = restoreLedgerMutation;

  return (
    <Dialog
      isOpen
      title={i18n.t(
        version ? "shares.restore.title.approved" : "shares.reset.title"
      )}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            variant="solid"
            color="primary"
            isLoading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            {i18n.t("shares.restore.label")}
          </Button>
        </>
      }
    >
      <div className="tw-space-y-4">
        <div className="tw-text-base">
          {version
            ? i18n.t("shares.restore.confirm.content", {
                version: version.label,
              })
            : i18n.t("shares.reset.confirm.content")}
        </div>
        {restoreLedgerMutation.error && (
          <FormErrorList error={restoreLedgerMutation.error} />
        )}
      </div>
    </Dialog>
  );
};

export { RestoreShares };
