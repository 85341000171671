import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { clsxm } from "../../../utils/tailwind";

type NewTooltipProps = {
  children: React.ReactElement;
  content: React.ReactNode;
  theme?: "light" | "dark";
  placement?: Placement;
  breakText?: boolean;
  className?: string;
};

const TooltipV2 = ({
  children,
  content,
  theme = "dark",
  placement = "top",
  breakText = false,
  className,
}: NewTooltipProps) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (newOpen) =>
      setIsOpen(newOpen && !!content && content !== ""),
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });
  const hover = useHover(context, { move: false });

  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    role,
  ]);

  const tooltipClasses = clsxm({
    "tw-z-50 tw-rounded tw-px-2 tw-py-1 tw-text-sm": true,
    "tw-bg-gray-700 tw-text-white": theme === "dark",
    "tw-bg-white tw-text-black tw-shadow-lg": theme !== "dark",
    "tw-max-w-xs tw-break-words": breakText,
  });

  const onClick = (e: React.SyntheticEvent) => {
    if (isTabletOrMobileDevice) {
      // Make clickable on mobile
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        type="button"
        onClick={onClick}
        className={className}
      >
        {children}
      </button>
      <FloatingPortal>
        {isOpen && (
          <div
            className={tooltipClasses}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {content}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export { TooltipV2 };
