import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { TParentEvent } from "../../../types/models/events";
import { Button } from "../../design-system/Button";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { ApproveConfirmation } from "./ApproveConfirmation";

type ApproveButtonProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  isRollback: boolean;
  disabled: boolean;
  event: TParentEvent & { label: string };
  onSuccess: () => void;
};

const ApproveButton = ({
  currentCompany,
  isRollback,
  disabled,
  event,
  onSuccess,
}: ApproveButtonProps) => {
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const i18n = useTranslation();
  const versionToApprove = event && {
    value: event.date,
    label: event.label,
  };

  return (
    <>
      <TooltipV2
        content={disabled ? i18n.t("label.approve.disabled") : undefined}
        className="tw-w-full md:tw-w-fit"
      >
        <Button
          color="primary"
          disabled={disabled}
          variant="solid"
          className="tw-w-full md:tw-w-fit"
          onClick={() => {
            setShowApprovePopup(true);
          }}
        >
          {i18n.t(
            isRollback ? "label.approveRollback" : "label.approvePending"
          )}
        </Button>
      </TooltipV2>
      {showApprovePopup && versionToApprove && (
        <ApproveConfirmation
          onClose={() => setShowApprovePopup(false)}
          isRollback={isRollback}
          version={versionToApprove}
          currentCompany={currentCompany}
          onSuccess={() => {
            setShowApprovePopup(false);
            onSuccess();
          }}
        />
      )}
    </>
  );
};

export { ApproveButton };
