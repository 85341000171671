import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { LedgerVersion } from "../../../types/models/shares";
import { Button } from "../../design-system/Button";
import { RejectConfirmation } from "./RejectConfirmation";

type RejectProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  currentVersion: LedgerVersion;
  isRollback: boolean;
  onSuccess: () => void;
};

const RejectButton = ({
  currentCompany,
  currentVersion,
  isRollback,
  onSuccess,
}: RejectProps) => {
  const i18n = useTranslation();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  return (
    <>
      <Button
        color="secondary"
        variant="outline"
        className="tw-w-full md:tw-w-fit"
        onClick={() => {
          setShowConfirmationPopup(true);
        }}
      >
        {i18n.t(isRollback ? "label.rejectRollback" : "label.rejectPending")}
      </Button>
      {showConfirmationPopup && (
        <RejectConfirmation
          currentCompany={currentCompany}
          isRollback={isRollback}
          onSuccess={onSuccess}
          onClose={() => setShowConfirmationPopup(false)}
          version={currentVersion}
        />
      )}
    </>
  );
};

export { RejectButton };
