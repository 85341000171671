import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import type { ShareIssueFormState } from "./types";

type InvestmentAmountProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  form: UseFormReturn<ShareIssueFormState>;
};

const InvestmentAmount = ({ currentCompany, form }: InvestmentAmountProps) => {
  const { t } = useTranslation();
  const lastEventDate = useLatestVersion();
  const { control, formState, register } = form;

  return (
    <>
      <div className="tw-flex tw-flex-col tw-justify-center tw-gap-6">
        <FormGroup>
          <FormLabel htmlFor="formationDate">{t("label.date")}</FormLabel>
          <Controller
            control={control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="formationDate"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="shareCapital.formationDate"
            rules={{ required: t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="totalShares">
            {t("label.totalNrOfShares")}
          </FormLabel>
          <Input
            id="totalShares"
            {...register("shareCapital.totalShares", {
              required: t("error.validation.required"),
              valueAsNumber: true,
              min: {
                value: 1,
                message: t("error.validation.range.min", { min: 1 }),
              },
            })}
            type="number"
            step={1}
          />
          <FormError>
            {formState.errors.shareCapital?.totalShares?.message}
          </FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="capitalAmount" isOptional>
            {t("label.investmentAmount")}
          </FormLabel>
          <div className="tw-flex tw-space-x-1">
            <Input
              id="capitalAmount"
              {...register("shareCapital.capitalAmount", {
                valueAsNumber: true,
                min: {
                  value: 0.000001,
                  message: t("error.validation.range.min", { min: 0.000001 }),
                },
              })}
              className="tw-flex-1"
              type="number"
              step={0.000001}
              prefix={currentCompany.settings?.currency}
            />
          </div>
          <FormError>
            {formState.errors.shareCapital?.capitalAmount?.message}
          </FormError>
        </FormGroup>
      </div>
    </>
  );
};

export default InvestmentAmount;
