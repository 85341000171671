import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useLedgerQuery,
  useShareholderBlocksQuery,
  useShareTypesQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useSplitShares } from "../../../api/rest/events";
import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import { Alert } from "../../../components/design-system/Alert";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { ArrowDownIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import type { TEventSummaryMetric } from "../../../components/EventSummary";
import {
  EventFormWrapper,
  EventSummarySectionList,
} from "../../../components/EventSummary";
import { ShareBlocksList } from "../../../components/ShareBlocks/List";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { TDraftShareBlock } from "../../../types/models/draft";
import { dateToIsoString } from "../../../utils/date";
import {
  generateNewBlocksProrata,
  getSplitMetrics,
} from "../../../utils/shares";

type SplitSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

type FormProps = {
  ratioX: number;
  ratioY: number;
  date: string;
  shareBlocks: TDraftShareBlock[];
};

const formId = "split-shares-form";

const getShareBlocksValidation = (
  shareBlocks: TDraftShareBlock[],
  sharesToAdd: number
) => {
  const numberOfSharesDiff =
    (shareBlocks.length > 0
      ? (shareBlocks.at(-1)?.end || 0) - (shareBlocks.at(0)?.start || 0) + 1
      : 0) - sharesToAdd;

  return { numberOfSharesDiff };
};

const SplitShares = ({
  currentCompany,
  onSuccess,
  setFormData,
}: SplitSharesProps) => {
  const i18n = useTranslation();
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { shareBlocks: [] },
  });
  const lastEventDate = useLatestVersion();
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareTypes = (shareTypesQuery.data || []).map((shareType) => ({
    ...shareType,
    numberOfShares: 0,
  }));

  const shareHoldersQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const allEntities = entitiesQuery.data || [];
  const shareHolders = shareHoldersQuery.data || [];
  const existingHolders = allEntities.filter((entity) =>
    shareHolders.some((element) => element.id === entity.id)
  );

  function gcd(input_a: number, input_b: number): number | undefined {
    if (!input_a || !input_b) {
      return undefined;
    }

    let a = input_a;
    let b = input_b;

    while (b !== 0) {
      const temp = b;
      b = a % b;
      a = temp;
    }

    return a;
  }

  const handleSuccess = () => {
    onSuccess();
  };

  const mutation = useSplitShares(currentCompany.orgNumber, {
    onSuccess: handleSuccess,
  });

  useEffect(() => {
    setFormData((data) => ({ ...data, formId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  const handleSubmit = (data: FormProps) => {
    if (!isModifierValid) {
      form.setError("ratioX", {
        type: "manual",
        message: i18n.t("error.validation.split.increase"),
      });
      form.setError("ratioY", {
        type: "manual",
        message: i18n.t("error.validation.split.increase"),
      });
      return;
    }
    if (shareBlocksValidation.numberOfSharesDiff !== 0) {
      form.setError("ratioX", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      form.setError("ratioY", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      return;
    }

    mutation.mutate({
      date: data.date,
      ratio: { x: data.ratioX, y: data.ratioY },
      shareRanges: data.shareBlocks,
    });
  };
  const { ratioX, ratioY, shareBlocks } = form.watch();
  const modifier = (ratioY || 1) / (ratioX || 1);

  const metrics = getSplitMetrics(modifier, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },
    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },
    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };

  const isModifierValid = modifier > 1;
  const greatestCommonDenominator = isModifierValid
    ? gcd(ratioX, ratioY)
    : undefined;
  const { dirtyFields, errors } = form.formState;

  useEffect(() => {
    form.setValue(
      "shareBlocks",
      generateNewBlocksProrata(
        modifier,
        shareHoldersQuery.data || [],
        ledgerQuery.data?.shares.lastNumber || 0
      )
    );
  }, [modifier]);

  const shareBlocksValidation = getShareBlocksValidation(
    shareBlocks,
    metrics.numberOfShares.after - metrics.numberOfShares.before
  );

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.split.title")}</h4>
        </div>
      </header>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form
          className="tw-space-y-6"
          onSubmit={form.handleSubmit(handleSubmit)}
          id={formId}
        >
          <FormGroup>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
            <Controller
              control={form.control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                    min={lastEventDate && dateToIsoString(lastEventDate.date)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="date"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <div className="tw-space-y-2">
            <FormGroup>
              <FormLabel htmlFor="ratioX">
                {i18n.t("events.split.ratioX")}
              </FormLabel>
              <Input
                id="ratioX"
                {...form.register("ratioX", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  validate: (val) => {
                    if (Number.isNaN(val)) {
                      return i18n.t("error.validation.required");
                    }
                    if (!(val > 0)) {
                      return i18n.t("error.validation.range.min.exclusive", {
                        min: 0,
                      });
                    }

                    return true;
                  },
                })}
                type="number"
              />
              <FormError>{form.formState.errors.ratioX?.message}</FormError>
            </FormGroup>
            <ArrowDownIcon className="tw-h-7 tw-w-7 tw-text-secondary" />
            <FormGroup>
              <FormLabel htmlFor="ratioY">
                {i18n.t("events.split.ratioY")}
              </FormLabel>
              <Input
                id="ratioY"
                {...form.register("ratioY", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  validate: (val) => {
                    if (Number.isNaN(val)) {
                      return i18n.t("error.validation.required");
                    }
                    if (!(val > 0)) {
                      return i18n.t("error.validation.range.min.exclusive", {
                        min: 0,
                      });
                    }

                    return true;
                  },
                })}
                type="number"
              />
              <FormError>{form.formState.errors.ratioY?.message}</FormError>
            </FormGroup>
            {isModifierValid ? (
              <div className="tw-pt-2">
                <Alert type="neutral">
                  {i18n.t("events.split.form.description", {
                    x: ratioX / greatestCommonDenominator!,
                    y: ratioY / greatestCommonDenominator!,
                  })}
                </Alert>
              </div>
            ) : (
              dirtyFields.ratioX &&
              dirtyFields.ratioY &&
              !errors.ratioX &&
              !errors.ratioY && (
                <div className="tw-pt-2">
                  <Alert type="error">
                    {i18n.t("error.validation.split.increase")}
                  </Alert>
                </div>
              )
            )}
          </div>
          {isModifierValid && (
            <Controller
              control={form.control}
              name="shareBlocks"
              render={({ field: { onChange, value } }) => (
                <ShareBlocksList
                  value={value}
                  onChange={onChange}
                  entitiesQuery={
                    { ...entitiesQuery, data: existingHolders } as ReturnType<
                      typeof useEntitiesQuery
                    >
                  }
                  currentCompany={currentCompany}
                  shareTypes={shareTypes}
                  offset={0}
                  disableNewEntity
                />
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          )}
          {isModifierValid && (
            <DistributionProgress
              diff={shareBlocksValidation.numberOfSharesDiff}
            />
          )}
          {mutation.error && <FormErrorList error={mutation.error} />}
        </form>
      </EventFormWrapper>
    </div>
  );
};

export default SplitShares;
