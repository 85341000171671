import { Ti18nKey } from "../../i18n";

const pageNames: { [key: string]: Ti18nKey } = {
  "/shares/holders": "label.shareholders",
  "/shares/blocks": "label.shareLedger",
  "/events": "label.events",
  "/options": "label.options",
  "/settings/entities": "label.entities",
  "/settings": "label.settings",
  "/company-information": "label.companyInformation",
  "/administration": "label.administration",
};

function getPageName(path: string) {
  const key = Object.keys(pageNames).find((n) => path.includes(n));
  if (key !== undefined) {
    return pageNames[key] || null;
  }
  return null;
}

const settingLinksRegEx = /\/settings\/.*/;

const normaliseName = (name?: string) => {
  if (!name) {
    return "";
  }
  if (name !== name.toUpperCase()) {
    return name;
  }
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter, and make the rest lowercase
    .join(" ");
};

export { getPageName, normaliseName, settingLinksRegEx };
