import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/design-system/Button";
import { PlusIcon } from "../../../../../components/design-system/icons";
import { EditShareTypeDialog } from "../Dialog";

type AddShareTypeProps = {
  list: ShareType[];
  onSuccess: (data: ShareType) => void;
};

const AddShareType = ({ list, onSuccess }: AddShareTypeProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();

  return (
    <>
      <Button
        variant="outline"
        color="secondary"
        onClick={() => setOpen(true)}
        prefix={<PlusIcon />}
      >
        {i18n.t("onboarding.action.addShareClass")}
      </Button>
      {isOpen && (
        <EditShareTypeDialog
          title={i18n.t("onboarding.action.addShareClass")}
          list={list}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          defaultValues={{
            name: "",
            condition: {
              consent: false,
              conversion: false,
              preemption: false,
              offerOfFirstRefusal: false,
              redemption: false,
            },
          }}
        />
      )}
    </>
  );
};

export { AddShareType };
