import React, { forwardRef } from "react";

import { clsxm } from "../../../utils/tailwind";
import { SpinnerIcon } from "../icons";

type Variant = "clean" | "outline" | "solid";
type Color = "danger" | "primary" | "secondary" | "kvanta-elegant";
type Size = "lg" | "md" | "sm" | "auto";

type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "prefix"
> & {
  color?: Color;
  variant?: Variant;
  isLoading?: boolean;
  prefix?: React.ReactElement;
  suffix?: React.ReactElement;
  size?: Size;
};

const Button = forwardRef(
  (
    {
      variant = "outline",
      size = "lg",
      color = "secondary",
      children,
      disabled,
      isLoading = false,
      prefix,
      suffix,
      className,
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      className={clsxm(
        "tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded tw-border tw-border-solid tw-py-2 tw-leading-5 tw-transition-all",
        {
          "tw-cursor-pointer hover:tw-opacity-80": !(isLoading || disabled),
          "tw-cursor-not-allowed tw-opacity-50 hover:tw-opacity-50":
            isLoading || disabled,
          "tw-border-primary tw-bg-primary tw-text-white":
            variant === "solid" && color === "primary",
          "tw-border-secondary tw-bg-secondary tw-text-white":
            variant === "solid" && color === "secondary",
          "tw-border-kvanta-elegant tw-bg-kvanta-elegant tw-text-white":
            variant === "solid" && color === "kvanta-elegant",
          "tw-border-error tw-bg-error tw-text-white":
            variant === "solid" && color === "danger",
          "tw-border-current tw-text-primary":
            variant === "outline" && color === "primary",
          "tw-text-body": variant === "outline" && color === "secondary",
          "tw-bg-text-white tw-border-kvanta-elegant tw-text-kvanta-elegant":
            variant === "outline" && color === "kvanta-elegant",
          "tw-border-current tw-text-error":
            variant === "outline" && color === "danger",
          "tw-h-auto tw-border-none tw-p-0 tw-text-left": variant === "clean",
          "tw-text-primary": variant === "clean" && color === "primary",
          "tw-text-inherit": variant === "clean" && color === "secondary",
          "tw-text-error": variant === "clean" && color === "danger",
          "tw-h-7 tw-px-2 tw-text-sm": size === "sm",
          "tw-h-10 tw-px-3 tw-text-sm tw-font-medium": size === "md",
          "tw-h-12 tw-px-4 tw-text-base tw-font-medium": size === "lg",
        },
        className
      )}
      disabled={isLoading || disabled}
      type="button"
      {...props}
    >
      {prefix && (
        <span
          className={clsxm("tw-flex tw-h-5 tw-w-5", {
            "tw-mr-1": size === "sm",
            "tw-mr-2": size === "lg" || size === "md",
          })}
        >
          {React.cloneElement(prefix, {
            className: "tw-w-full tw-h-full tw-text-current",
          })}
        </span>
      )}
      {children}
      {suffix && (
        <span
          className={clsxm("tw-flex tw-h-5 tw-w-5", {
            "tw-ml-1": size === "sm",
            "tw-ml-2": size === "lg" || size === "md",
          })}
        >
          {React.cloneElement(suffix, {
            className: "tw-w-full tw-h-full tw-text-current",
          })}
        </span>
      )}
      {isLoading && <SpinnerIcon className="tw-ml-2 tw-h-4 tw-w-4" />}
    </button>
  )
);

export { Button };
export type { ButtonProps, Color, Variant };
