/* eslint-disable react/jsx-no-literals */
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useCompaniesQuery } from "../../../../../api/blockchain/company";
import { useUpdateCompanyStatusAndAccessMutation } from "../../../../../api/rest/administration";
import { Button } from "../../../../../components/design-system/Button";
import { Description } from "../../../../../components/design-system/Description";
import {
  FormGroup,
  FormLabel,
} from "../../../../../components/design-system/FormGroup";
import { Input } from "../../../../../components/design-system/Input";
import { List } from "../../../../../components/design-system/List";
import { Loading } from "../../../../../components/design-system/Loading";
import { notify } from "../../../../../components/design-system/Notifications";
import { Select } from "../../../../../components/design-system/Select";
import type {
  BaseCompanyEntity,
  CompanyLegalStatus,
} from "../../../../../types/models/company";
import * as monitoring from "../../../../../utils/monitoring";

const availableCompanyStatusOptions: {
  value: CompanyLegalStatus;
  label: string;
}[] = [
  { value: "underLiquidation", label: "Under liquidation" },
  { value: "liquidated", label: "Liquidated" },
];

const UpdateCompanyStatusPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [newStatus, setStatus] =
    useState<CompanyLegalStatus>("underLiquidation");
  const [liquidators, setLiquidators] = useState<BaseCompanyEntity[]>([]);
  const [newLiquidator, setNewLiquidator] = useState<BaseCompanyEntity>({
    name: "",
    countryCode: "SE",
    refId: "",
  });
  const companies = useCompaniesQuery();
  const updateCompanyRequest = useUpdateCompanyStatusAndAccessMutation({
    onSuccess: () => {
      notify(
        <Description
          title="Company status updated"
          description="The company status has been updated successfully."
        />,
        { type: "success" }
      );
      companies.refetch();
    },
    onError: (error) => {
      monitoring.captureException(error);

      notify(
        <Description
          title="Failed to update company status"
          description="Couldn't update company status. Please try again."
        />,
        { type: "error" }
      );
    },
  });
  const canAssignLiquidators = newStatus === "underLiquidation";

  useEffect(() => {
    if (newStatus !== "underLiquidation") {
      setLiquidators([]);
    }
  }, [newStatus]);

  const isValid = useMemo(() => {
    if (!companyId) {
      return false;
    }

    if (!newStatus) {
      return false;
    }
    if (newStatus === "underLiquidation") {
      return liquidators.length > 0;
    }
    return true;
  }, [newStatus, liquidators, companyId]);

  if (updateCompanyRequest.isLoading) {
    return <Loading />;
  }

  const updateCompany = async () => {
    if (!isValid) {
      return;
    }
    try {
      await updateCompanyRequest.mutateAsync({
        orgNumber: companyId as string,
        status: newStatus,
        liquidators,
      });
    } catch (error) {
      monitoring.captureException(error);
    }
  };

  return (
    <div className="tw-max-w-lg">
      <h3 className="tw-flex tw-items-center tw-justify-between tw-py-4">
        <div>Update status</div>
        <Button
          onClick={() => updateCompany()}
          disabled={!isValid || updateCompanyRequest.isLoading}
        >
          Update company
        </Button>
      </h3>
      {updateCompanyRequest.isError && (
        <div className="tw-text-red-500">
          <pre>{JSON.stringify(updateCompanyRequest.error, null, 2)}</pre>
        </div>
      )}
      <FormGroup>
        <FormLabel htmlFor="companyStatus" className="tw-text-md">
          Company status
        </FormLabel>
        <Select
          id="companyStatus"
          className="tw-mb-4 tw-max-w-xs"
          options={availableCompanyStatusOptions}
          value={availableCompanyStatusOptions.find(
            (status) => status.value === newStatus
          )}
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }
            setStatus(selectedOption.value);
          }}
        />
      </FormGroup>

      {canAssignLiquidators && (
        <>
          <FormGroup>
            <FormLabel className="tw-text-md tw-py-4">Liquidators</FormLabel>
            <List>
              {liquidators.map((liquidator, index) => (
                <div
                  className="tw-m-2 tw-rounded tw-border tw-p-2"
                  key={liquidator.refId}
                >
                  <div className="tw-flex tw-items-center">
                    <div className="tw-flex-1">{liquidator.name}</div>
                    <div className="tw-flex-1">{liquidator.refId}</div>
                    <div className="tw-flex-1">
                      <Button
                        size="sm"
                        onClick={() => {
                          setLiquidators([
                            ...liquidators.slice(0, index),
                            ...liquidators.slice(index + 1),
                          ]);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </List>
          </FormGroup>
          <FormGroup className="tw-items-top">
            <FormLabel className="tw-text-md">New liquidator</FormLabel>
            <FormGroup>
              <FormLabel htmlFor="new-liquidator-name">Name</FormLabel>
              <Input
                id="new-liquidator-name"
                type="text"
                value={newLiquidator?.name}
                onChange={(event) =>
                  setNewLiquidator({
                    ...newLiquidator,
                    name: event.target.value,
                  })
                }
              />
              <FormLabel htmlFor="new-liquidator-ref-id">
                Personnummer
              </FormLabel>
              <Input
                id="new-liquidator-ref-id"
                type="text"
                value={newLiquidator?.refId}
                onChange={(event) =>
                  setNewLiquidator({
                    ...newLiquidator,
                    refId: event.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup className="tw-flex tw-justify-center">
              <Button
                className="tw-mt-4"
                variant="solid"
                color="primary"
                disabled={
                  !newLiquidator?.name ||
                  !newLiquidator?.refId ||
                  newLiquidator.name.length < 1 ||
                  newLiquidator.refId.length < 10
                }
                onClick={() => {
                  setLiquidators([...liquidators, newLiquidator]);
                  setNewLiquidator({
                    countryCode: "SE",
                    name: "",
                    refId: "",
                  });
                }}
              >
                Add liquidator
              </Button>
            </FormGroup>
          </FormGroup>
        </>
      )}
    </div>
  );
};

export { UpdateCompanyStatusPage };
