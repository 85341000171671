import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import { CompanyInformation } from "../../types/models/administration";
import { LoggedInUser } from "../../types/models/auth";
import { CompanyInvolvement } from "../../types/models/company";
import { Entity } from "../../types/models/entities";
import { hasRequiredPermission } from "../../utils/permissions";
import { EventsBanner } from "../AddEvents/Banner/EventsBanner";

type EventActionsBarProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  user?: LoggedInUser;
  setShowEventsWizard: (value: boolean) => void;
  pendingApprovalBy: Entity[];
  approvedBy: Entity[];
};

const EventActionsBar: React.FC<EventActionsBarProps> = ({
  currentCompany,
  user,
  setShowEventsWizard,
  pendingApprovalBy,
  approvedBy,
}) => {
  const { draftEvents, pendingEvents, pendingRollbackEvents } =
    useCompanyEvents(currentCompany?.orgNumber);

  return (
    <div
      id="mobile"
      className="tw-z-45 tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-gap-2 tw-border-t-[1px] tw-bg-white tw-p-4 sm:tw-hidden"
    >
      {hasRequiredPermission("Editor", currentCompany, user) && (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-stretch tw-gap-2 max-md:tw-grid">
          <EventsBanner
            totalDraftEvents={draftEvents.length}
            inReview={
              pendingEvents.length > 0 || pendingRollbackEvents.length > 0
            }
            isRollback={pendingRollbackEvents.length > 0}
            onClick={() => setShowEventsWizard(true)}
            pendingApprovalBy={pendingApprovalBy}
            approvedBy={approvedBy}
          />
        </div>
      )}
    </div>
  );
};

export { EventActionsBar };
