import { ShareType } from "@capchapdev/rell-api";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import type { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  TDraftShareBlock,
  TDraftShareType,
} from "../../../types/models/draft";
import { formatNumber } from "../../../utils/format";
import { calcSumWithinRange } from "../../../utils/shares";
import { Badge } from "../../design-system/Badge";
import { Button } from "../../design-system/Button";
import { Description } from "../../design-system/Description";
import { EntityItem } from "../../design-system/EntityItem";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  TrashIcon,
} from "../../design-system/icons";
import { List, ListItem } from "../../design-system/List";
import { ListHeader } from "../../design-system/ListHeader";
import { NoData } from "../../NoData";
import { AddShareBlock } from "../Add";
import { EditShareBlock } from "../Edit";

type ShareBlocksListProps = {
  value: TDraftShareBlock[];
  onChange: (value: TDraftShareBlock[]) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: TDraftShareType[];
  offset?: number;
  disableNewEntity?: boolean;
};

const swapItems = (
  value: TDraftShareBlock[],
  prevIndex: number,
  currentIndex: number
) => {
  const prevBlock = value[prevIndex];
  const block = value[currentIndex];
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const blockSize = calcSumWithinRange(block);

  return value.map((b, bIndex) =>
    bIndex === prevIndex
      ? {
          ...block,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          start: prevBlock.start,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          end: prevBlock.start + blockSize - 1,
        }
      : bIndex === currentIndex
      ? {
          ...prevBlock,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          start: prevBlock.start + blockSize,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          end: block.end,
        }
      : b
  );
};

type ShareBlockRowControlsProps = {
  index: number;
  block: TDraftShareBlock;
  items: TDraftShareBlock[];
  onChange: (value: TDraftShareBlock[]) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: ShareType[];
  disableNewEntity?: boolean;
};

const ShareBlockRowControls = ({
  index,
  block,
  items,
  onChange,
  entitiesQuery,
  currentCompany,
  shareTypes,
  disableNewEntity,
}: ShareBlockRowControlsProps) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const blockSize = calcSumWithinRange(block);

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-gap-1 md:tw-order-last md:tw-justify-end">
      {index !== items.length - 1 && (
        <Button
          variant={isTabletOrMobileDevice ? "outline" : "clean"}
          size="md"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ start: number; end: number; ho... Remove this comment to see the full error message
            onChange(swapItems(items, index, index + 1));
          }}
        >
          <ArrowDownIcon className="tw-h-6 tw-w-6" />
        </Button>
      )}
      {index !== 0 && (
        <Button
          variant={isTabletOrMobileDevice ? "outline" : "clean"}
          size="md"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ start: number; end: number; ho... Remove this comment to see the full error message
            onChange(swapItems(items, index - 1, index));
          }}
        >
          <ArrowUpIcon className="tw-h-6 tw-w-6" />
        </Button>
      )}
      <EditShareBlock
        disableNewEntity={disableNewEntity}
        currentCompany={currentCompany}
        entitiesQuery={entitiesQuery}
        shareTypes={shareTypes}
        initialValue={{
          numberOfShares: blockSize,
          holderId: block.holderId,
          type: block.type,
        }}
        onSuccess={(newValue) => {
          const diff = (newValue.numberOfShares || 0) - blockSize;
          onChange(
            items.map((b) =>
              b.start < block.start
                ? b
                : block.start === b.start
                ? {
                    start: block.start,
                    end: block.end + diff,
                    type: newValue.type || "",
                    holderId: newValue.holderId || "",
                  }
                : { ...b, start: b.start + diff, end: b.end + diff }
            )
          );
        }}
      />
      <Button
        variant={isTabletOrMobileDevice ? "outline" : "clean"}
        size="md"
        onClick={(e) => {
          e.stopPropagation();
          const withoutCurrentBlock = items.filter(
            (b) => b.start !== block.start
          );

          onChange(
            withoutCurrentBlock.map((b) =>
              b.start < block.start
                ? b
                : {
                    ...b,
                    start: b.start - blockSize,
                    end: b.end - blockSize,
                  }
            )
          );
        }}
      >
        <TrashIcon className="tw-h-6 tw-w-6" />
      </Button>
    </div>
  );
};

const ShareBlocksList = ({
  value,
  onChange,
  entitiesQuery,
  currentCompany,
  shareTypes,
  offset = 0,
  disableNewEntity,
}: ShareBlocksListProps) => {
  const i18n = useTranslation();
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));

  return (
    <List
      header={
        <ListHeader
          title={i18n.t("label.shareBlocks")}
          actions={
            <AddShareBlock
              currentCompany={currentCompany}
              entitiesQuery={entitiesQuery}
              shareTypes={shareTypes}
              onSuccess={(newValue) => {
                const previousEnd = value.at(-1)?.end || offset;
                onChange([
                  ...value,
                  {
                    start: previousEnd + 1,
                    end: previousEnd + (newValue.numberOfShares || 0),
                    type: newValue.type || "",
                    holderId: newValue.holderId || "",
                  },
                ]);
              }}
              disableNewEntity={disableNewEntity}
            />
          }
        />
      }
    >
      {value.length === 0 && <NoData />}
      {value.map((block, blockIndex) => {
        const blockSize = calcSumWithinRange(block);
        // TODO: pass unmaskedRefId to this component, too, so we can use
        // `getEntityWithFallback`-function
        const entity = entitiesMap[block.holderId];

        if (entity === undefined) {
          console.error("Entity is undefined");

          return null;
        }

        return (
          <ListItem key={block.start}>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3">
              <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                <Description
                  title={`${formatNumber(block.start)} - ${formatNumber(
                    block.end
                  )}`}
                  description={formatNumber(blockSize)}
                />
                <div className="tw-flex tw-items-center">
                  <Badge>{block.type}</Badge>
                </div>
              </div>
              <ShareBlockRowControls
                index={blockIndex}
                block={block}
                items={value}
                onChange={onChange}
                entitiesQuery={entitiesQuery}
                currentCompany={currentCompany}
                shareTypes={shareTypes}
                disableNewEntity={disableNewEntity}
              />
              <div className="tw-col-span-2 md:tw-col-span-1">
                <EntityItem value={entity} />
              </div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

export { ShareBlockRowControls, ShareBlocksList };
