import { Navigate } from "react-router-dom";

import { Loading } from "../../../../components/design-system/Loading";
import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { APP_ROUTE } from "../../../../routes/constants";
import { NewTimeline } from "../../../CompanyShares/NewTimeline";

const CompanyEventsPage: React.FunctionComponent = () => {
  const currentCompany = useCurrentCompany();

  if (currentCompany === undefined) {
    return <Loading />;
  }

  if (currentCompany.companyStatus === "Onboarded") {
    return (
      <PageWrapper
        className="max-sm:tw-px-4 max-sm:tw-py-0"
        data-testid="company-events-page"
      >
        <NewTimeline currentCompany={currentCompany} />
      </PageWrapper>
    );
  }

  return (
    <Navigate
      to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/onboarding`}
    />
  );
};

export { CompanyEventsPage };
