import { useTranslation } from "react-i18next";
import { MenuPlacement } from "react-select";

import type { Condition, ShareTypeClause } from "../../../types/models/shares";
import type { MenuPosition, OptionProps } from "../../design-system/Select";
import { Option as SelectOption, Select } from "../../design-system/Select";

type Option = { value: ShareTypeClause; label: string; description: string };

const ClausesOption = (props: OptionProps<Option, true>) => {
  const { data } = props;

  return (
    <SelectOption {...props}>
      <div>{data.label}</div>
      <div className="tw-text-sm tw-text-secondary">{data.description}</div>
    </SelectOption>
  );
};

const useRestrictiveConditionOptions = () => {
  const i18n = useTranslation();
  const options: {
    value: ShareTypeClause;
    label: string;
    description: string;
  }[] = [
    {
      value: "consent",
      label: i18n.t("shares.restrictiveConditions.consent"),
      description: i18n.t("shares.restrictiveConditions.consent.description"),
    },
    {
      value: "preemption",
      label: i18n.t("shares.restrictiveConditions.preemption"),
      description: i18n.t(
        "shares.restrictiveConditions.preemption.description"
      ),
    },
    {
      value: "conversion",
      label: i18n.t("shares.restrictiveConditions.conversion"),
      description: i18n.t(
        "shares.restrictiveConditions.conversion.description"
      ),
    },
    {
      value: "offerOfFirstRefusal",
      label: i18n.t("shares.restrictiveConditions.offerOfFirstRefusal"),
      description: i18n.t(
        "shares.restrictiveConditions.offerOfFirstRefusal.description"
      ),
    },
    {
      value: "redemption",
      label: i18n.t("shares.restrictiveConditions.redemption"),
      description: i18n.t(
        "shares.restrictiveConditions.redemption.description"
      ),
    },
  ];

  return options;
};

const SelectClauses = ({
  value,
  onChange,
  menuPosition,
  menuPlacement,
  isDisabled,
}: {
  value: Condition;
  onChange: (newValue?: Condition) => void;
  menuPosition?: MenuPosition;
  menuPlacement?: MenuPlacement;
  isDisabled?: boolean;
}) => {
  const options = useRestrictiveConditionOptions();

  return (
    <Select
      value={options.filter((option) => value[option.value])}
      options={options}
      components={{ Option: ClausesOption }}
      onChange={(newValue = []) => {
        const valueList = new Set(newValue.map((v) => v.value));
        const valuePairs = options.map((option) => [
          option.value,
          valueList.has(option.value),
        ]);
        onChange(Object.fromEntries(valuePairs));
      }}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      isMulti
      isDisabled={isDisabled}
    />
  );
};

export { SelectClauses, useRestrictiveConditionOptions };
