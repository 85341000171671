import { Plus } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  GroupBase,
  MenuPlacement,
  MultiValueProps as DefaultMultiValueProps,
  StylesConfig,
} from "react-select";
import { components } from "react-select";
import { twMerge } from "tailwind-merge";

import { useUsersQuery } from "../../api/blockchain/users";
import { useEntitiesQuery } from "../../api/rest/entities";
import { useAddUserMutation } from "../../api/rest/users";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { Entity } from "../../types/models/entities";
import type { ShareholderBlocks } from "../../types/models/shares";
import type { NewUserRole, UserListResponse } from "../../types/models/users";
import { formatRefId } from "../../utils/format";
import { Button } from "../design-system/Button";
import { Description } from "../design-system/Description";
import { EntityItem } from "../design-system/EntityItem";
import { notify } from "../design-system/Notifications";
import type { MenuPosition, OptionProps } from "../design-system/Select";
import { Option as SelectOption, Select } from "../design-system/Select";
import { getShareBlocks } from "../design-system/SelectEntity";
import { EditUserDialog } from "../Users/Dialog";

type IOptionProps = OptionProps<Entity, true> & {
  shareBlocks?: ShareholderBlocks[];
};

const Option = (props: IOptionProps) => {
  return (
    <SelectOption {...props}>
      <EntityItem
        value={props.data}
        additional={getShareBlocks(props.data, props.shareBlocks)}
        hasFlag={false}
        displayIcon={false}
      />
    </SelectOption>
  );
};

type MultiValuePropsModified = DefaultMultiValueProps<Entity, true> & {
  shareBlocks?: ShareholderBlocks[];
};

const MultiValue = (props: MultiValuePropsModified) => {
  const { data } = props;

  return (
    <components.MultiValue
      {...props}
      className={twMerge(["!tw-whitespace-break-spaces", props.className])}
    >
      <div className="tw-text-sm">
        <span className="tw-mr-2 tw-text-sm">{data.name}</span>
        <span className="tw-text-sm">{formatRefId(data)}</span>
      </div>
    </components.MultiValue>
  );
};

type SelectMultiEntitiesProps = {
  value?: string[] | null;
  onChange: (newValue: string[]) => void;
  suffix?: React.ReactNode;
  menuPosition?: MenuPosition;
  disabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  shareBlocks?: ShareholderBlocks[];
  currentCompany: CompanyInvolvement | CompanyInformation;
  usersList: UserListResponse;
};

const SelectMultiEntities: React.FunctionComponent<
  SelectMultiEntitiesProps
> = ({
  value,
  onChange,
  suffix,
  menuPosition,
  disabled,
  isClearable,
  menuPlacement,
  shareBlocks,
  currentCompany,
  usersList,
}) => {
  const i18n = useTranslation();

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const options = useMemo(
    () =>
      (entitiesQuery.isSuccess && entitiesQuery.data
        ? entitiesQuery.data
        : []
      ).filter((entity) => entity.type === "Private"),
    [entitiesQuery.data, entitiesQuery.isSuccess]
  );

  const selectedOptions = options?.filter((option) =>
    value?.includes(option.id)
  );

  const dropDownStyles: StylesConfig<Entity, true, GroupBase<Entity>> = {
    menu: (provided) => ({
      ...provided,
      maxWidth: "100%",
    }),
    menuList: (provided) => ({
      ...provided,
      width: "1000px",
      maxWidth: "100%",
    }),
    option: (provided) => ({
      ...provided,
      padding: "12px 20px",
    }),
  };

  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const userIds = usersList.map((user) => user.user.id);
  const filteredOptions = options?.filter((option) =>
    userIds.includes(option.id)
  );

  const handleUsersChange = () => {
    entitiesQuery.refetch();
    usersQuery.refetch();
  };

  const addUserMutation = useAddUserMutation(currentCompany.orgNumber, {
    onSuccess: () => {
      setIsAddOpen(false);
      notify(
        <Description
          title={i18n.t("label.addNewExternalUserSuccess")}
          description={i18n.t("label.addNewExternalUserSuccess.description")}
        />,
        { type: "success" }
      );
      handleUsersChange();
    },
  });

  const handleAddSubmit = (data: NewUserRole) => {
    addUserMutation.mutate({
      role: data.role,
      entityId: data.entityId,
    });
  };

  return (
    <div
      className="tw-relative tw-items-center tw-space-y-1"
      data-testid="select-entity"
    >
      {isAddOpen && (
        <EditUserDialog
          currentCompany={currentCompany}
          title={i18n.t("label.addNewExternalUser")}
          onClose={() => setIsAddOpen(false)}
          handleOnSubmit={handleAddSubmit}
          mutation={addUserMutation}
        />
      )}
      <Select
        className="tw-flex-1"
        isMulti
        components={{
          Option: (props) => <Option {...props} shareBlocks={shareBlocks} />,
          MultiValue,

          MenuList: (props) => (
            <components.MenuList {...props}>
              {props.children}
              <Button
                type="button"
                variant="clean"
                className="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-p-5 tw-text-base"
                onClick={() => {
                  setIsAddOpen(true);
                }}
              >
                <div className="tw-flex">
                  <Plus /> <span>{i18n.t("label.addNewExternalUser")}</span>
                </div>
              </Button>
            </components.MenuList>
          ),
        }}
        getOptionLabel={(option) => `[${option.name}`}
        getOptionValue={(option) => option.id}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        options={filteredOptions}
        value={selectedOptions}
        onChange={(changedOptions) => {
          const selectedIds = changedOptions?.map((option) => option.id) || [];
          onChange(selectedIds);
        }}
        styles={dropDownStyles}
        autoFocus
        defaultMenuIsOpen
      />
      {suffix}
    </div>
  );
};

export { SelectMultiEntities };
