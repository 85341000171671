import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useApprovalInfoQuery } from "../../../api/blockchain/company";
import { useApprovalRuleProposalQuery } from "../../../api/blockchain/users";
import { useLedgerVersions } from "../../../pages/CompanyShares/useLedgerVersions";
import { APP_ROUTE } from "../../../routes/constants";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { EntitiesMap } from "../../../types/models/entities";
import { LedgerVersion } from "../../../types/models/shares";
import { Button } from "../../design-system/Button";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { PublishConfirmation } from "./PublishConfirmation";

type PublishButtonProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  version?: LedgerVersion;
  entitiesMap: EntitiesMap;
  onSuccess: () => void;
};

const useApprovalDisabled = (orgNumber: string) => {
  const pendingApprovalRuleProposalQuery =
    useApprovalRuleProposalQuery(orgNumber);
  const versions = useLedgerVersions(orgNumber);
  const versionToApproveApprovalInfo = useApprovalInfoQuery(
    orgNumber,
    versions[0]?.formatedValue
  );

  if (
    versionToApproveApprovalInfo.isLoading ||
    pendingApprovalRuleProposalQuery.isLoading
  ) {
    return false;
  }

  const hasTheBoardSelectedAnApprovalPolicy =
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None";

  const isApprovalPolicyPending =
    pendingApprovalRuleProposalQuery.isSuccess &&
    !!pendingApprovalRuleProposalQuery.data;

  return !hasTheBoardSelectedAnApprovalPolicy || isApprovalPolicyPending;
};

const PublishButton = ({
  currentCompany,
  version,
  entitiesMap,
  onSuccess,
}: PublishButtonProps) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const i18n = useTranslation();
  const disabled = useApprovalDisabled(currentCompany.orgNumber);

  return (
    <>
      {disabled && (
        <Link
          to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings`}
        >
          <Button className="tw-w-full md:tw-w-fit">
            {i18n.t("label.setPolicy")}
          </Button>
        </Link>
      )}
      <div className="tw-w-full md:tw-w-fit">
        <TooltipV2
          content={
            !version || disabled
              ? disabled
                ? i18n.t("initiatePolicy.approvalPolicyRequired")
                : i18n.t("eventsWizard.table.empty.title")
              : null
          }
          className="tw-w-full"
        >
          <Button
            className="tw-w-full md:tw-w-fit"
            color="primary"
            disabled={!version || disabled}
            variant="solid"
            onClick={() => {
              setShowConfirmationPopup(true);
            }}
          >
            {i18n.t("eventsWizard.publish")}
          </Button>
        </TooltipV2>
      </div>
      {showConfirmationPopup && version && (
        <PublishConfirmation
          currentCompany={currentCompany}
          entitiesMap={entitiesMap}
          onSuccess={onSuccess}
          onClose={() => setShowConfirmationPopup(false)}
          version={version}
        />
      )}
    </>
  );
};

export { PublishButton };
