import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { Button } from "../../../../../components/design-system/Button";
import { EditShareTypeDialog } from "../Dialog";

type EditShareTypeProps = {
  initialValue: ShareType;
  disabled: boolean;
  list: ShareType[];
  onSuccess: (data: ShareType) => void;
  sharesOnly?: boolean;
};

const EditShareType = ({
  initialValue,
  disabled,
  list,
  onSuccess,
}: EditShareTypeProps) => {
  const [isOpen, setOpen] = useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const i18n = useTranslation();

  return (
    <>
      <Button
        disabled={disabled}
        variant={isTabletOrMobileDevice ? "outline" : "clean"}
        size="md"
        onClick={() => setOpen(true)}
      >
        {i18n.t("label.edit")}
      </Button>
      {isOpen && (
        <EditShareTypeDialog
          title={i18n.t("onboarding.action.editShareClass")}
          list={list}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          defaultValues={initialValue}
        />
      )}
    </>
  );
};

export { EditShareType };
