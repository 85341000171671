import { useApprovalInfoQuery } from "../../api/blockchain/company";
import type { ApprovalRule } from "../../api/rest/approval-rule-policy";
import type { CompanyInformation } from "../../types/models/administration";
import type { LoggedInUser } from "../../types/models/auth";
import type { CompanyInvolvement } from "../../types/models/company";
import { EntitiesMap } from "../../types/models/entities";
import {
  hiddenLedgerEvents,
  type TParentEvent,
} from "../../types/models/events";
import type { ApprovalInfoResponse } from "../../types/models/shares";
import { isGreaterLedgerVersion } from "../../utils/date";
import {
  getEventValueLabelPair,
  isApprovalEvent,
} from "../../utils/events-utils";
import { hasRequiredPermission } from "../../utils/permissions";
import { assertNever } from "../../utils/type";

const validateApprovalPermissions = (
  approvalStatus: NonNullable<ApprovalInfoResponse>,
  approvalRule: ApprovalRule,
  company: CompanyInvolvement | CompanyInformation,
  user: LoggedInUser
): boolean => {
  switch (approvalRule.rule) {
    case "SpecificUsers": {
      const userCanManuallyApprove = approvalRule.users.some(
        ({ id }) => id === user.id
      );
      const waitingForUserApproval =
        approvalStatus.rule === "SpecificUsers" &&
        approvalStatus.pendingApprovalBy.some(({ id }) => id === user.id);

      return userCanManuallyApprove && waitingForUserApproval;
    }
    case "BoardPercentage": {
      return hasRequiredPermission("BoardMember", company);
    }
    case "None": {
      return false;
    }
    default: {
      return assertNever(approvalRule);
    }
  }
};

const willUserApprovalFinalize = (
  versionToApproveApprovalInfo?: ApprovalInfoResponse,
  userId?: string
): boolean => {
  if (
    userId === undefined ||
    !versionToApproveApprovalInfo ||
    versionToApproveApprovalInfo.rule === "None" ||
    versionToApproveApprovalInfo.status !== "Pending"
  ) {
    return false;
  }

  const isUserApprover = versionToApproveApprovalInfo.pendingApprovalBy.some(
    ({ id }) => id === userId
  );

  const willCompletePercentage =
    versionToApproveApprovalInfo.rule === "BoardPercentage" &&
    versionToApproveApprovalInfo.currentNumber + 1 ===
      versionToApproveApprovalInfo.requiredNumber;
  const willCompleteSpecificUsers =
    versionToApproveApprovalInfo.rule === "SpecificUsers";

  const willComplete = willCompletePercentage || willCompleteSpecificUsers;

  return isUserApprover && willComplete;
};

const getVersionToApprove = (events: TParentEvent[]) => {
  const filteredEvents = events.filter(
    (e) => hiddenLedgerEvents.includes(e.type) === false
  );
  const lastInitializedApprovalEvent = filteredEvents.find(
    (e) =>
      e.type === "LedgerApprovalInitialized" ||
      e.type === "LedgerRollbackPending"
  );

  const lastApprovalEvent = filteredEvents.find(
    (e) => e.type === "LedgerApproval"
  );

  let versionToApproveFromLastInitialization = null;
  if (
    lastApprovalEvent?.date &&
    lastInitializedApprovalEvent?.date &&
    (isGreaterLedgerVersion(
      lastApprovalEvent.date,
      lastInitializedApprovalEvent.date
    ) ||
      lastApprovalEvent.date === lastInitializedApprovalEvent.date)
  ) {
    versionToApproveFromLastInitialization = filteredEvents.find(
      (e) =>
        isGreaterLedgerVersion(e.date, lastInitializedApprovalEvent.date) &&
        !isApprovalEvent(e)
    );
  } else if (lastInitializedApprovalEvent) {
    versionToApproveFromLastInitialization = filteredEvents.find(
      (e) => e.date === lastInitializedApprovalEvent.date && !isApprovalEvent(e)
    );
  }

  if (!versionToApproveFromLastInitialization) {
    return null;
  }

  return getEventValueLabelPair(versionToApproveFromLastInitialization);
};

const sortEvents = (
  events: TParentEvent[],
  sortBy: "date-asc" | "date-desc"
) => {
  const sortOrder = sortBy === "date-desc" ? -1 : 1;
  return events.sort((a, b) => {
    if (a.date === b.date) {
      return 0;
    }
    const [dateA, versionA] = a.date.split(".");
    const [dateB, versionB] = b.date.split(".");
    if (dateA === dateB) {
      // If dates are equal, check version
      return parseInt(versionA!, 10) > parseInt(versionB!, 10)
        ? sortOrder
        : -1 * sortOrder;
    }
    return new Date(dateA!) > new Date(dateB!) ? sortOrder : -1 * sortOrder;
  });
};

const useApproversStatus = (
  orgNumber: string,
  date: string | undefined,
  entitiesMap: EntitiesMap
) => {
  const versionToApproveApprovalInfo = useApprovalInfoQuery(orgNumber, date);
  const approvalData =
    versionToApproveApprovalInfo.data &&
    versionToApproveApprovalInfo.data.rule !== "None" &&
    versionToApproveApprovalInfo.data.status === "Pending" &&
    versionToApproveApprovalInfo.data;
  const approvedBy = approvalData
    ? approvalData.approvedBy
        .map(({ id }) => entitiesMap[id])
        .filter((x) => x !== undefined)
    : [];
  const pendingApprovalBy = approvalData
    ? approvalData.pendingApprovalBy
        .map(({ id }) => entitiesMap[id])
        .filter((x) => x !== undefined)
    : [];
  return { approvedBy, pendingApprovalBy };
};

export {
  getVersionToApprove,
  sortEvents,
  useApproversStatus,
  validateApprovalPermissions,
  willUserApprovalFinalize,
};
