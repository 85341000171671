import { useMemo } from "react";

import { useVersionsQuery } from "../../api/blockchain/events";
import { useCurrentCompany } from "../../context/account";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import type { DateVersion } from "../../types/models/shares";
import { getFormattedLedgerVersion } from "../../utils/date";

type LedgerVersionDetails = DateVersion & { isApproved: boolean };

const useLedgerVersions = (
  orgNumber = "",
  status: "all" | "approved" = "all"
): LedgerVersionDetails[] => {
  const company = useCurrentCompany(orgNumber);
  const versionsQuery = useVersionsQuery(orgNumber);
  const companyEvents = useCompanyEvents(orgNumber);
  const allEvents = useMemo(() => {
    const events = [];
    events.push(...companyEvents.approvedEvents);
    events.push(...companyEvents.pendingEvents);
    events.push(...companyEvents.pendingRollbackEvents);
    events.push(...companyEvents.draftEvents);
    return events;
  }, [companyEvents]);

  const availableVersions = useMemo(() => {
    if (
      !company ||
      !versionsQuery.data ||
      versionsQuery.isLoading ||
      versionsQuery.isError ||
      allEvents.length === 0
    ) {
      return [];
    }
    return versionsQuery.data
      .filter((version) => {
        const event = allEvents.find(
          (e) =>
            e.date === version &&
            e.type !== "LedgerPolicyApproval" &&
            e.type !== "LedgerRollbackPending"
        );
        return !!event;
      })
      .map((version) => {
        const dateVersion = getFormattedLedgerVersion(version);
        const versionIsApproved = [
          ...companyEvents.approvedEvents,
          ...companyEvents.pendingRollbackEvents,
        ].some((event) => event.date === version);

        if (status === "approved" && !versionIsApproved) {
          return null;
        }

        return {
          ...dateVersion,
          isApproved: versionIsApproved,
        };
      })
      .filter(Boolean) as LedgerVersionDetails[];
  }, [allEvents, company, status, versionsQuery]);

  return availableVersions;
};

export { type LedgerVersionDetails, useLedgerVersions };
