import { ParentEventWithStatus } from "../../api/blockchain/events";
import { filterEvents } from "./filter-events";

const splitEvents = <T extends ParentEventWithStatus>(
  events: T[]
): {
  approvedEvents: T[];
  pendingEvents: T[];
  pendingRollbackEvents: T[];
  draftEvents: T[];
} => {
  const groupedItems = filterEvents(events).reduce((acc, item) => {
    const key = item.status;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);
    return acc;
  }, {} as { [key: string]: T[] });

  return {
    approvedEvents: groupedItems.Approved || [],
    pendingEvents: groupedItems.Pending || [],
    pendingRollbackEvents: groupedItems.PendingRollback || [],
    draftEvents: groupedItems.Draft || [],
  };
};

export { splitEvents };
