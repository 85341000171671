import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import type { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { NewShareBlock } from "../../../types/models/draft";
import { Button } from "../../design-system/Button";
import { PencilIcon } from "../../design-system/icons";
import { EditShareBlockDialog } from "../Dialog";

type EditShareBlockProps = {
  onSuccess: (data: NewShareBlock) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: ShareType[];
  initialValue: NewShareBlock;
  disableNewEntity?: boolean;
};

const EditShareBlock = ({
  onSuccess,
  entitiesQuery,
  currentCompany,
  shareTypes,
  initialValue,
  disableNewEntity,
}: EditShareBlockProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <>
      <Button
        variant={isTabletOrMobileDevice ? "outline" : "clean"}
        size="md"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <PencilIcon className="tw-h-6 tw-w-6" />
      </Button>
      {isOpen && (
        <EditShareBlockDialog
          title={i18n.t("onboarding.action.editShareBlock")}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          entitiesQuery={entitiesQuery}
          currentCompany={currentCompany}
          shareTypes={shareTypes}
          defaultValues={initialValue}
          disableNewEntity={disableNewEntity}
        />
      )}
    </>
  );
};

export { EditShareBlock };
