import { ShareType } from "@capchapdev/rell-api";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/design-system/Button";
import { Dialog } from "../../../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../../../components/design-system/FormGroup";
import { Input } from "../../../../../components/design-system/Input";
import { SelectClauses } from "../../../../../components/ShareTypes/SelectRestrictiveConditions";
import {
  NewShareType,
  TDraftShareType,
} from "../../../../../types/models/draft";

const formId = "edit-sharetype-form";

type EditShareTypeDialogProps = {
  title: string;
  list: ShareType[];
  onClose: () => void;
  onSuccess: (value: ShareType) => void;
  defaultValues: NewShareType;
};

const EditShareTypeDialog = ({
  title,
  list,
  onClose,
  onSuccess,
  defaultValues,
}: EditShareTypeDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TDraftShareType>({ mode: "onChange", defaultValues });

  const minVote = Math.min(...list.map((type) => type.voteValue));
  const maxVote = Math.max(...list.map((type) => type.voteValue));

  return (
    <Dialog
      isOpen
      title={title}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button type="submit" variant="solid" color="primary" form={formId}>
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) =>
            onSuccess({ ...data, name: data.name.trim() })
          )(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="name">{i18n.t("label.name")}</FormLabel>
          <Input
            id="name"
            {...register("name", {
              validate: (value) => {
                if (!value.trim()) {
                  return i18n.t("error.validation.required");
                }
                if (
                  value !== defaultValues.name &&
                  list.some(
                    (sibling) =>
                      sibling.name.trim().toLowerCase() ===
                      value.trim().toLowerCase()
                  )
                ) {
                  return i18n.t("error.validation.unique");
                }

                return true;
              },
            })}
          />
          <FormError>{errors.name?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="voteValue">
            {i18n.t("label.numberOfVotesPerShare")}
          </FormLabel>
          <Input
            id="voteValue"
            {...register("voteValue", {
              required: i18n.t("error.validation.required"),
              valueAsNumber: true,
              validate: (val) => {
                if (Number.isNaN(val) || val <= 0) {
                  return i18n.t("error.validation.range.greaterThanOrEqual", {
                    min: 0,
                  });
                }

                const range = { min: maxVote / 10, max: minVote * 10 };
                if (val < range.min || val > range.max) {
                  return i18n.t("error.validation.range", range);
                }

                return true;
              },
            })}
            type="number"
          />
          <FormError>{errors.voteValue?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="condition" isOptional>
            {i18n.t("shares.restrictiveConditions")}
          </FormLabel>
          <Controller
            name="condition"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectClauses
                  value={value}
                  onChange={onChange}
                  menuPosition="fixed"
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
      </form>
    </Dialog>
  );
};

export { EditShareTypeDialog };
