import { Clock, PencilSimpleLine, ThumbsUp } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { useSession } from "../../../context/session";
import { Entity } from "../../../types/models/entities";
import { Badge } from "../../design-system/Badge";
import { Button } from "../../design-system/Button";
import { PlusIcon, RestoreIcon } from "../../design-system/icons";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { nameToInitials } from "../../EventList/EventsTable/EventsTable.utils";
import image from "./diamond.png";

type BannerProps = {
  totalDraftEvents: number;
  inReview: boolean;
  isRollback: boolean;
  pendingApprovalBy: Entity[];
  approvedBy: Entity[];
  onClick: () => void;
};

const ApproverBadge = ({
  isApproved = false,
  entity,
}: {
  isApproved?: boolean;
  entity: Entity;
}) => (
  <TooltipV2
    content={`${entity.name} (${entity.passportNumber || entity.refId})`}
  >
    <Badge color={isApproved ? "success" : "none"}>
      <div className="tw-flex tw-items-center tw-gap-1">
        {nameToInitials(entity.name)}{" "}
        {isApproved ? <ThumbsUp size={15} /> : <Clock size={15} />}
      </div>
    </Badge>
  </TooltipV2>
);

const maxBadgesToDisplay = 3;

const EventsBanner = ({
  totalDraftEvents,
  inReview,
  isRollback,
  pendingApprovalBy,
  approvedBy,
  onClick,
}: BannerProps) => {
  const i18n = useTranslation();
  const { user } = useSession();

  if (!inReview && totalDraftEvents === 0) {
    return (
      <Button
        color="primary"
        variant="solid"
        onClick={() => onClick()}
        prefix={<PlusIcon />}
      >
        {i18n.t("events.add")}
      </Button>
    );
  }

  const userApprovedEntity = approvedBy.find((x) => x.id === user?.id);
  const userPendingApprovalEntity = pendingApprovalBy.find(
    (x) => x.id === user?.id
  );
  const reviewBadgeList = [
    ...(userApprovedEntity
      ? [
          <ApproverBadge
            key={userApprovedEntity.id}
            entity={userApprovedEntity}
            isApproved
          />,
        ]
      : []),
    ...(userPendingApprovalEntity
      ? [
          <ApproverBadge
            key={userPendingApprovalEntity.id}
            entity={userPendingApprovalEntity}
          />,
        ]
      : []),
    ...pendingApprovalBy
      .filter((x) => x.id !== user?.id)
      .map((x) => <ApproverBadge key={x.id} entity={x} />),
    ...approvedBy
      .filter((x) => x.id !== user?.id)
      .map((x) => <ApproverBadge key={x.id} entity={x} />),
  ];

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded md:tw-border md:tw-p-2">
      <div className="max-md:tw-hidden">
        {!inReview ? (
          <PencilSimpleLine />
        ) : isRollback ? (
          <RestoreIcon />
        ) : (
          <img src={image} className="tw-h-8 tw-w-8" alt="logo" />
        )}
      </div>
      <div className="tw-flex-grow">
        <h3 className="tw-text-sm tw-font-medium">
          {inReview
            ? isRollback
              ? i18n.t("eventsBanner.rollback")
              : i18n.t("eventsBanner.review")
            : i18n.t("eventsBanner.drafts", { total: totalDraftEvents })}
        </h3>
        <p className="tw-text-sm tw-text-secondary">
          {inReview ? (
            <div className="tw-flex tw-gap-2">
              {reviewBadgeList.length > maxBadgesToDisplay ? (
                <>
                  {reviewBadgeList.slice(0, maxBadgesToDisplay)}
                  <Badge>+{reviewBadgeList.length - maxBadgesToDisplay}</Badge>
                </>
              ) : (
                reviewBadgeList
              )}
            </div>
          ) : (
            i18n.t("eventsBanner.add")
          )}
        </p>
      </div>
      <div>
        <Button
          color={inReview && !isRollback ? "kvanta-elegant" : "primary"}
          variant="solid"
          size="md"
          onClick={() => onClick()}
          className="tw-h-full"
        >
          <span className="tw-whitespace-normal">
            {inReview
              ? pendingApprovalBy.find((u) => u.id === user?.id)
                ? i18n.t("label.preview")
                : i18n.t("label.previewAndApprove")
              : i18n.t("label.open")}
          </span>
        </Button>
      </div>
    </div>
  );
};

export { EventsBanner };
