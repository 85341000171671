/* eslint-disable react/jsx-no-literals */
import { Checkbox } from "../../../components/design-system/Checkbox";
import { Description } from "../../../components/design-system/Description";
import { notify } from "../../../components/design-system/Notifications";
import { PageWrapper } from "../../../components/PageWrapper";
import { featureToggles } from "../../../config";
import { useToggles } from "../../../hooks/useToggles";

const FeatureTogglesPage = () => {
  const { isFeatureEnabled, toggleFeature } = useToggles();
  const features = Object.values(featureToggles);

  return (
    <PageWrapper className="tw-m-auto tw-max-w-2xl tw-flex-col tw-p-5">
      <h1 className="tw-pb-5">Feature Toggles</h1>
      <div className="tw-px-10">
        <p>Feature toggles are used to enable/disable features in the app.</p>
        <p>
          They are cached locally in the client so no persistence when you log
          in from different clients.
        </p>
      </div>

      <h2 className="tw-pt-10">Available Features:</h2>
      <div className="tw-flex tw-flex-col tw-px-10 tw-py-2">
        {features.map((featureToggle) => {
          const isEnabled = isFeatureEnabled(featureToggle);
          return (
            <Checkbox
              className="tw-list-item"
              key={featureToggle}
              checked={isEnabled}
              onChange={() => {
                try {
                  toggleFeature(featureToggle, !isEnabled);
                  notify(<Description title="Save complete" />, {
                    type: "success",
                  });
                } catch (error) {
                  console.error(error);
                  notify(<Description title="Save failed" />, {
                    type: "error",
                  });
                }
              }}
            >
              {featureToggle}
            </Checkbox>
          );
        })}
      </div>
    </PageWrapper>
  );
};

export { FeatureTogglesPage };
